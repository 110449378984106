@import url(http://fonts.googleapis.com/css?family=Open+Sans);
#top-nav {
  height: 45px;
  padding-right: 10px;
  background-color: #ffffff;
  white-space: nowrap;
  min-width: 310px;
  border-bottom: 1px solid #f2f2f2;
}
#top-nav.fixed {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1000;
}
@media (max-width: 767px) {
  #top-nav.fixed {
    left: -194px;
  }
}
#top-nav .brand {
  display: block;
  text-align: center;
  font-size: 17px;
  line-height: 45px;
  width: 194px;
  color: #999999;
  background: #323447;
  float: left;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
#top-nav .brand:hover,
#top-nav .brand:focus {
  color: #fff;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
#top-nav .page-title {
  float: left;
  line-height: 45px;
  font-weight: 500;
  color: #999999;
}
@media (max-width: 767px) {
  #top-nav .page-title {
    display: none;
  }
}
#top-nav .navbar-toggle {
  margin: 3px 0 0 0;
}
#top-nav .navbar-toggle.hide-menu {
  display: block;
  margin-top: 5px;
}
@media (max-width: 767px) {
  #top-nav .navbar-toggle.hide-menu {
    display: none;
  }
}
#top-nav .navbar-toggle .icon-bar {
  display: block;
  width: 18px;
  height: 2px;
  margin-bottom: 3px;
  background-color: #999999;
  border-radius: 1px;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
}
#top-nav .navbar-toggle .icon-bar:last-child {
  margin-bottom: 0;
}
#top-nav .nav-notification {
  list-style: none;
  margin: 0;
  float: right;
  white-space: nowrap;
  font-size: 12px;
}
@media (max-width: 480px) {
  #top-nav .nav-notification {
    font-size: 11px;
  }
}
#top-nav .nav-notification > li {
  display: block;
  position: relative;
  float: left;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
#top-nav .nav-notification > li.open {
  background: #fcfcfc;
}
#top-nav .nav-notification > li.open .dropdown-menu {
  animation: fadeInUp 0.8s ease;
  -webkit-animation: fadeInUp 0.8s ease;
  -moz-animation: fadeInUp 0.8s ease;
  -ms-animation: fadeInUp 0.8s ease;
  -o-animation: fadeInUp 0.8s ease;
}
#top-nav .nav-notification > li:last-child {
  border-right: none;
}
#top-nav .nav-notification > li:hover {
  background: #fcfcfc;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
#top-nav .nav-notification > li.profile:hover a,
#top-nav .nav-notification > li.profile:focus a {
  animation: none;
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  -o-animation: none;
}
#top-nav .nav-notification > li.profile:hover a > strong,
#top-nav .nav-notification > li.profile:focus a > strong {
  display: inline-block;
  animation: fadeInRight 0.2s ease-in-out;
  -webkit-animation: fadeInRight 0.2s ease-in-out;
  -moz-animation: fadeInRight 0.2s ease-in-out;
  -ms-animation: fadeInRight 0.2s ease-in-out;
  -o-animation: fadeInRight 0.2s ease-in-out;
}
#top-nav .nav-notification > li.profile:hover a > span,
#top-nav .nav-notification > li.profile:focus a > span {
  display: inline-block;
  animation: fadeInLeft 0.2s ease-in-out;
  -webkit-animation: fadeInLeft 0.2s ease-in-out;
  -moz-animation: fadeInLeft 0.2s ease-in-out;
  -ms-animation: fadeInLeft 0.2s ease-in-out;
  -o-animation: fadeInLeft 0.2s ease-in-out;
}
#top-nav .nav-notification > li.profile .dropdown-menu {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  min-width: 220px;
}
#top-nav .nav-notification > li.profile .dropdown-menu li:first-child {
  margin-bottom: 10px;
}
#top-nav .nav-notification > li.profile .dropdown-menu li:first-child a:hover {
  background: #fff;
  color: #000;
}
#top-nav .nav-notification > li.profile .dropdown-menu li:not(:first-child) a {
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
#top-nav .nav-notification > li.profile .dropdown-menu li:not(:first-child) a:hover,
#top-nav .nav-notification > li.profile .dropdown-menu li:not(:first-child) a:focus {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  background: transparent;
  color: #000;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
#top-nav .nav-notification > li > a {
  display: block;
  position: relative;
  padding: 13px 15px;
  color: #999999;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
#top-nav .nav-notification > li > a:hover,
#top-nav .nav-notification > li > a:focus {
  color: #777777;
  text-decoration: none;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
@media (max-width: 480px) {
  #top-nav .nav-notification > li > a {
    padding: 14px 15px;
  }
}
#top-nav .nav-notification .dropdown-menu {
  right: 0;
  left: auto;
  top: 95%;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-color: #f1f5fc;
}
@media (max-width: 480px) {
  #top-nav .nav-notification .dropdown-menu.dropdown-1 {
    left: -50px !important;
    right: auto !important;
  }
}
@media (max-width: 480px) {
  #top-nav .nav-notification .dropdown-menu.dropdown-2 {
    left: -80px !important;
    right: auto !important;
  }
}
@media (max-width: 480px) {
  #top-nav .nav-notification .dropdown-menu.dropdown-3 {
    left: -120px !important;
    right: auto !important;
  }
}
#top-nav .nav-notification .dropdown-menu li {
  border-bottom-color: #f1f5fc;
}
@media (max-width: 480px) {
  #top-nav {
    min-width: 310px;
  }
}
@media (min-width: 481px) and (max-width: 550px) {
  #top-nav {
    min-width: 471px;
  }
}
@media (min-width: 551px) and (max-width: 600px) {
  #top-nav {
    min-width: 541px;
  }
}
@media (min-width: 601px) and (max-width: 670px) {
  #top-nav {
    min-width: 591px;
  }
}
@media (max-width: 767px) {
  #top-nav {
    left: -194px;
  }
}
@media (min-width: 768px) and (max-width: 868px) {
  #top-nav {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
  #top-nav .brand {
    width: 90px;
  }
  #top-nav .brand .text-toggle {
    display: none;
  }
}
.breadcrumb {
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  background: transparent;
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
  margin-bottom: 0;
  font-size: 11px;
  font-weight: bold;
  box-shadow: 0 1px 0 #ffffff;
  -moz-box-shadow: 0 1px 0 #ffffff;
  -webkit-box-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px #ffffff;
}
.breadcrumb li {
  line-height: 27px;
  color: #777;
}
.breadcrumb a {
  text-decoration: none;
  color: #777;
}
aside {
  position: absolute;
  display: block;
  float: left;
  width: 194px;
  z-index: 100;
  padding-top: 45px;
  bottom: 0;
  left: 0;
  background-color: #323447;
  height: 100%;
}
aside::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
aside::-webkit-scrollbar-thumb {
  background-color: rgba(50, 50, 50, 0.4);
}
aside.fixed {
  position: fixed;
}
aside .size-toggle {
  padding: 10px;
  border-bottom: 1px solid #2c2d3e;
  box-shadow: 0 1px 0 #34364a;
  -moz-box-shadow: 0 1px 0 #34364a;
  -webkit-box-shadow: 0 1px 0 #34364a;
}
@media (max-width: 868px) {
  aside .size-toggle {
    display: none;
  }
}
aside .size-toggle .btn {
  background-color: transparent;
  color: #e6f1f7;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
aside .size-toggle .btn.pull-right {
  margin-top: -3px;
}
aside .size-toggle .btn .icon-bar {
  background-color: #e6f1f7;
  display: block;
  width: 13px;
  height: 2px;
  margin-bottom: 2px;
  border-radius: 1px;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
aside .size-toggle .btn:hover,
aside .size-toggle .btn:focus {
  color: #ffffff;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
aside .size-toggle .btn:hover .icon-bar,
aside .size-toggle .btn:focus .icon-bar {
  background-color: #ffffff;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
aside .sidebar-inner {
  border-right: 1px solid #272938;
}
aside .sidebar-inner .user-block {
  padding: 10px;
  border-bottom: 1px solid #2c2d3e;
  box-shadow: 0 1px 0 #34364a;
  -moz-box-shadow: 0 1px 0 #34364a;
  -webkit-box-shadow: 0 1px 0 #34364a;
}
aside .sidebar-inner .user-block img {
  float: left;
  width: 45px;
  height: 45px;
  border-radius: 50em;
  -moz-border-radius: 50em;
  -webkit-border-radius: 50em;
  animation: fadeInRotate 0.9s ease;
  -webkit-animation: fadeInRotate 0.9s ease;
  -moz-animation: fadeInRotate 0.9s ease;
  -ms-animation: fadeInRotate 0.9s ease;
  -o-animation: fadeInRotate 0.9s ease;
}
aside .sidebar-inner .user-block .detail {
  float: left;
  color: #e6f1f7;
  margin-left: 10px;
}
aside .sidebar-inner .user-block ul {
  margin-top: 5px;
}
aside .sidebar-inner .user-block ul li {
  padding: 0;
}
aside .sidebar-inner .user-block ul li a {
  font-size: 11px;
  color: #e6f1f7;
  margin-right: 10px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
aside .sidebar-inner .user-block ul li a:hover,
aside .sidebar-inner .user-block ul li a:focus {
  color: #ffffff;
  text-decoration: none;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
aside .sidebar-inner .search-block {
  padding: 10px;
  border-bottom: 1px solid #2c2d3e;
  box-shadow: 0 1px 0 #34364a;
  -moz-box-shadow: 0 1px 0 #34364a;
  -webkit-box-shadow: 0 1px 0 #34364a;
}
aside .sidebar-inner .search-block input[type="text"] {
  background: #272938;
  border: 1px solid #272938;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
aside .sidebar-inner .search-block input[type="text"]:focus {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
aside .sidebar-inner .search-block input[type="text"]::-webkit-input-placeholder,
aside .sidebar-inner .search-block input[type="text"]:-moz-placeholder,
aside .sidebar-inner .search-block input[type="text"]:-ms-input-placeholder {
  color: #fff;
}
aside .sidebar-inner .search-block .btn {
  color: #ccc;
  background: #272938;
  border: 1px solid #272938;
}
aside .main-menu {
  height: 100%;
}
aside .main-menu > ul {
  margin: 0;
  list-style: none;
}
aside .main-menu > ul > li {
  position: relative;
  border-top: 1px solid #34364a;
  border-bottom: 1px solid #2c2d3e;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
aside .main-menu > ul > li.active {
  border-top: 1px solid #323447;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
aside .main-menu > ul > li.active > a {
  color: #ffffff;
  background: #2c2d3e;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15) inset;
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15) inset;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15) inset;
}
aside .main-menu > ul > li.active .menu-hover {
  opacity: 1;
}
aside .main-menu > ul > li.display {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
aside .main-menu > ul > li.display > a {
  color: #ffffff;
  background: #2c2d3e;
}
aside .main-menu > ul > li.display .menu-hover {
  opacity: 1;
}
aside .main-menu > ul > li > a {
  position: relative;
  display: block;
  color: #e6f1f7;
  font-size: 13px;
  padding: 13px 10px 13px 13px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  transition: color 0.5s ease, background 0.5s ease;
  -webkit-transition: color 0.5s ease, background 0.5s ease;
  -moz-transition: color 0.5s ease, background 0.5s ease;
  -ms-transition: color 0.5s ease, background 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease;
}
aside .main-menu > ul > li > a:hover,
aside .main-menu > ul > li > a:focus {
  text-decoration: none;
  color: #ffffff;
  transition: color 0.5s ease, background 0.5s ease;
  -webkit-transition: color 0.5s ease, background 0.5s ease;
  -moz-transition: color 0.5s ease, background 0.5s ease;
  -ms-transition: color 0.5s ease, background 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease;
  background: #272938;
}
aside .main-menu > ul > li > a:hover .menu-hover,
aside .main-menu > ul > li > a:focus .menu-hover {
  opacity: 1;
  transition: opacity 0.5s ease;
  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
}
aside .main-menu > ul > li > a .text {
  margin-left: 10px;
}
aside .main-menu > ul > li > a .badge {
  position: absolute;
  top: 12px;
  right: 7px;
}
aside .main-menu > ul > li > a .menu-hover {
  position: absolute;
  background: #feffd8;
  box-shadow: 0 0 20px 0px #ffffff;
  -moz-box-shadow: 0 0 20px 0px #ffffff;
  -webkit-box-shadow: 0 0 20px 0px #ffffff;
  opacity: 0;
  top: -2px;
  bottom: -2px;
  left: 0px;
  width: 3px;
  transition: opacity 0.5s ease;
  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
}
aside .main-menu > ul > li .dropdown-menu {
  animation: fadeIn 0.8s ease;
  -webkit-animation: fadeIn 0.8s ease;
  -moz-animation: fadeIn 0.8s ease;
  -ms-animation: fadeIn 0.8s ease;
  -o-animation: fadeIn 0.8s ease;
}
@media (min-width: 768px) {
  aside .main-menu > ul > li .dropdown-menu {
    right: -160px;
    left: auto;
    top: -5px;
  }
}
@media (max-width: 767px) {
  aside .main-menu > ul > li .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  aside .main-menu > ul > li .dropdown-menu li a {
    color: #e6f1f7;
  }
  aside .main-menu > ul > li .dropdown-menu li a:hover {
    color: #ffffff;
    background: #1d1e29;
  }
}
aside .main-menu > ul > li .submenu {
  display: none;
}
aside .main-menu > ul > li .submenu.third-level li a {
  padding-left: 40px;
}
aside .main-menu > ul > li .submenu.fourth-level li a {
  padding-left: 60px;
}
aside .main-menu > ul > li .submenu li {
  position: relative;
}
aside .main-menu > ul > li .submenu li a {
  display: block;
  background-color: #383b50;
  color: #fafcfd;
  font-weight: normal;
  padding: 9px 20px;
  font-size: 12px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
aside .main-menu > ul > li .submenu li a .submenu-label {
  display: inline-block;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
aside .main-menu > ul > li .submenu li a:hover .submenu-label {
  color: #fff;
  transform: translateX(5px);
  -webkit-transform: translateX(5px);
  -moz-transform: translateX(5px);
  -ms-transform: translateX(5px);
  -o-transform: translateX(5px);
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
aside .main-menu .alert {
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  text-align: center;
  animation: fadeInUp 1.2s ease;
  -webkit-animation: fadeInUp 1.2s ease;
  -moz-animation: fadeInUp 1.2s ease;
  -ms-animation: fadeInUp 1.2s ease;
  -o-animation: fadeInUp 1.2s ease;
}
@media (max-width: 767px) {
  aside {
    left: -194px;
  }
}
@media (min-width: 768px) and (max-width: 868px) {
  aside {
    width: 90px;
  }
  aside .brand .text-toggle {
    display: none;
  }
  aside .slimScrollDiv {
    overflow: visible !important;
  }
  aside .slimScrollDiv .slimScrollBar {
    opacity: 0 !important;
  }
  aside .slimScrollDiv .slimScrollRail {
    opacity: 0 !important;
  }
  aside .sidebar-inner {
    overflow: visible !important;
  }
  aside .sidebar-inner .size-toggle {
    text-align: center;
  }
  aside .sidebar-inner .size-toggle .pull-right {
    display: none;
  }
  aside .sidebar-inner .user-block {
    text-align: center;
  }
  aside .sidebar-inner .user-block img {
    float: none;
  }
  aside .sidebar-inner .user-block .detail {
    display: none;
  }
  aside .sidebar-inner .search-block {
    display: none;
  }
  aside .sidebar-inner .main-menu > ul > li > a {
    text-align: center;
    font-size: 11px;
  }
  aside .sidebar-inner .main-menu > ul > li > a .menu-icon {
    display: block;
  }
  aside .sidebar-inner .main-menu > ul > li > a .text {
    display: block;
    margin-top: 5px;
    margin-left: 0;
  }
  aside .sidebar-inner .main-menu > ul > li > a .badge {
    display: none;
  }
  aside .sidebar-inner .alert {
    display: none;
  }
}
#wrapper {
  position: relative;
  overflow: hidden;
  min-height: 800px;
  background-color: #f9f9f9;
}
@media (min-width: 768px) {
  #wrapper.sidebar-mini #top-nav .brand {
    width: 90px;
  }
  #wrapper.sidebar-mini #top-nav .brand .text-toggle {
    display: none;
  }
  #wrapper.sidebar-mini aside {
    width: 90px;
  }
  #wrapper.sidebar-mini aside .slimScrollDiv {
    overflow: visible !important;
  }
  #wrapper.sidebar-mini aside .slimScrollDiv .slimScrollBar {
    opacity: 0 !important;
  }
  #wrapper.sidebar-mini aside .slimScrollDiv .slimScrollRail {
    opacity: 0 !important;
  }
  #wrapper.sidebar-mini aside .sidebar-inner {
    overflow: visible !important;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .size-toggle {
    text-align: center;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .size-toggle .pull-right {
    display: none;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .user-block {
    text-align: center;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .user-block img {
    float: none;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .user-block .detail {
    display: none;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .search-block {
    display: none;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .main-menu > ul > li:hover .submenu {
    display: block;
    animation: fadeIn 0.4s ease;
    -webkit-animation: fadeIn 0.4s ease;
    -moz-animation: fadeIn 0.4s ease;
    -ms-animation: fadeIn 0.4s ease;
    -o-animation: fadeIn 0.4s ease;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .main-menu > ul > li:hover .submenu.third-level,
  #wrapper.sidebar-mini aside .sidebar-inner .main-menu > ul > li:hover .submenu.fourth-level {
    display: none;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .main-menu > ul > li > a {
    text-align: center;
    font-size: 11px;
    padding: 13px 10px;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .main-menu > ul > li > a .menu-icon {
    display: block;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .main-menu > ul > li > a .text {
    display: block;
    margin-top: 5px;
    margin-left: 0;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .main-menu > ul > li > a .badge {
    display: none;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .main-menu > ul > li .submenu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    list-style: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    right: -160px;
    left: auto;
    top: -5px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .main-menu > ul > li .submenu li {
    position: relative;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .main-menu > ul > li .submenu li:hover .submenu.third-level {
    display: block;
    animation: fadeIn 0.4s ease;
    -webkit-animation: fadeIn 0.4s ease;
    -moz-animation: fadeIn 0.4s ease;
    -ms-animation: fadeIn 0.4s ease;
    -o-animation: fadeIn 0.4s ease;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .main-menu > ul > li .submenu li a {
    background: #fff;
    text-align: left;
    padding: 4px 20px;
    color: #333;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .main-menu > ul > li .submenu li a .submenu-label {
    color: #333 !important;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .main-menu > ul > li .submenu li a:hover {
    background-color: #f2f2f2;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .main-menu > ul > li .submenu li a:hover .submenu-label {
    color: #222 !important;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .main-menu > ul > li .submenu.third-level li:hover .submenu.fourth-level {
    display: block;
    animation: fadeIn 0.4s ease;
    -webkit-animation: fadeIn 0.4s ease;
    -moz-animation: fadeIn 0.4s ease;
    -ms-animation: fadeIn 0.4s ease;
    -o-animation: fadeIn 0.4s ease;
  }
  #wrapper.sidebar-mini aside .sidebar-inner .alert {
    display: none;
  }
  #wrapper.sidebar-mini #main-container,
  #wrapper.sidebar-mini footer {
    margin-left: 90px;
  }
}
@media (max-width: 767px) {
  #wrapper.sidebar-display aside {
    left: 0;
  }
  #wrapper.sidebar-display #top-nav {
    left: 0;
    right: -194px;
  }
  #wrapper.sidebar-display #main-container,
  #wrapper.sidebar-display footer {
    left: 194px;
    right: -194px;
  }
}
@media (min-width: 768px) {
  #wrapper.sidebar-hide aside {
    left: -194px;
  }
  #wrapper.sidebar-hide #main-container,
  #wrapper.sidebar-hide footer {
    margin-left: 0;
  }
}
body {
  padding-top: 0;
  font-size: 12px;
  color: #777777;
  background: #f9f9f9;
  font-family: 'Open Sans', sans-serif;
}
body.dark {
  background: #3a3a3a;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(50, 50, 50, 0.3);
}
::-webkit-scrollbar-track {
  background-color: rgba(50, 50, 50, 0.2);
}
img {
  max-width: 100%;
}
ul {
  padding: 0;
}
.well {
  background-color: #e8e8e8;
  background-image: -moz-linear-gradient(top, #f5f5f5, #e8e8e8);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f5f5f5), to(#e8e8e8));
  background-image: -webkit-linear-gradient(top, #f5f5f5, #e8e8e8);
  background-image: -o-linear-gradient(top, #f5f5f5, #e8e8e8);
  background-image: linear-gradient(to bottom, #f5f5f5, #e8e8e8);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#f5f5f5, endColorstr=#e8e8e8, GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
hr {
  border-top-color: #eee;
  border-bottom-color: #fff;
}
a {
  color: #555;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
a:hover,
a:focus {
  color: #999;
  text-decoration: none;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.navbar.navbar-fixed-top {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}
.navbar.navbar-inverse {
  background-color: #111;
}
.list-group-item,
a.list-group-item {
  border-color: #f1f5fc;
  color: #777;
}
.pagination {
  margin-bottom: 0;
}
.pagination.pagination-xs > li > a,
.pagination.pagination-xs > li > span {
  padding: 2px 7px;
}
.pagination.pagination-split li {
  display: inline-block;
  margin-right: 3px;
}
.pagination.pagination-split li a {
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
.pagination li.active a,
.pagination li.active a:hover,
.pagination li.active a:focus {
  background: #5a5a5a;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.7) inset;
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.7) inset;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.7) inset;
}
.pagination li a {
  color: #777;
}
.pagination li a:hover,
.pagination li a:focus {
  background: #f2f2f2;
}
.progress {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  height: 12px;
}
.progress .progress-bar {
  background-color: #6bafbd;
}
.progress .progress-bar.animated-bar {
  animation: progress-start 3s linear;
  -webkit-animation: progress-start 3s linear;
  -moz-animation: progress-start 3s linear;
  -ms-animation: progress-start 3s linear;
  -o-animation: progress-start 3s linear;
}
.progress .progress-bar.progress-bar-success {
  background-color: #65cea7;
}
.progress .progress-bar.progress-bar-warning {
  background-color: #f3ce85;
}
.progress .progress-bar.progress-bar-danger {
  background-color: #fc8675;
}
.label,
.badge {
  background-color: #F1F5FC;
  color: #777;
}
.label-success,
.badge-success {
  background-color: #65cea7;
  color: #fff;
}
.label-danger,
.badge-danger {
  background-color: #fc8675;
  color: #fff;
}
.label-warning,
.badge-warning {
  background-color: #f3ce85;
  color: #fff;
}
.label-info,
.badge-info {
  background-color: #6bafbd;
  color: #fff;
}
.label-primary,
.badge-primary {
  background-color: #424f63;
  color: #fff;
}
.alert {
  color: #8B6420;
  background: #fcf3e2;
  border: 1px solid #f3ce85;
  padding: 10px;
}
.alert.alert-info {
  color: #0f5d84;
  background: #b2d5dc;
  border: 1px solid #6bafbd;
}
.alert.alert-success {
  color: #1b601c;
  background: #b2e7d3;
  border: 1px solid #65cea7;
}
.alert.alert-danger {
  color: #691715;
  background: #feded9;
  border: 1px solid #fc8675;
}
#theme-setting {
  position: fixed;
  top: 120px;
  right: -212px;
  color: #777;
  z-index: 40;
  display: inline-block;
  width: 210px;
  padding-bottom: 10px;
  background: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
#theme-setting.open {
  right: 0px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
#theme-setting .title {
  padding: 5px;
  color: #555;
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #f1f5fc;
}
#theme-setting strong {
  display: block;
  margin-bottom: 15px;
}
#theme-setting hr {
  margin: 0;
  border-top-color: #f1f5fc;
  border-bottom: 1px solid #fff;
}
#theme-setting .theme-box {
  padding: 10px 20px;
}
#theme-setting a {
  cursor: pointer;
}
#theme-setting input {
  height: auto;
  width: 180px;
  background: #fff;
  color: #626262;
}
#theme-setting-icon {
  position: fixed;
  display: inline-block;
  top: 159px;
  right: 0px;
  font-size: 18px;
  color: #777;
  z-index: 40;
  cursor: pointer;
  padding: 11px;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  -webkit-border-radius: 3px 0 0 3px;
  background: #fff;
  border: 1px solid #f1f5fc;
  border-width: 1px 0 1px 1px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
#theme-setting-icon:hover,
#theme-setting-icon:focus {
  text-decoration: none;
  color: #555;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
#theme-setting-icon.open {
  right: 210px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.brand-name {
  font-size: 16px;
  line-height: 40px;
  font-weight: 600;
}
.gmnoprint img {
  max-width: none;
}
.avatar {
  width: 60px;
}
.grey-container {
  background: #eee;
  padding: 15px;
  border: 1px solid #dfdfdf;
  border-width: 1px 0 1px 0;
}
.shortcut-wrapper {
  text-align: center;
}
@media (max-width: 767px) {
  .shortcut-wrapper {
    text-align: left;
  }
}
.shortcut-link {
  display: inline-block;
  color: #ccc;
  margin-right: 20px;
  text-shadow: 0 1px 0 #fff;
  transition: color 0.2s ease;
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  -ms-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
}
.shortcut-link .shortcut-icon {
  position: relative;
  font-size: 30px;
}
.shortcut-link .shortcut-icon .shortcut-alert {
  position: absolute;
  border-radius: 50em;
  -moz-border-radius: 50em;
  -webkit-border-radius: 50em;
  font-size: 11px;
  color: #fff;
  top: 0;
  right: 0;
  background: #fc8675;
  border: 1px solid #fc8675;
  text-shadow: none;
  font-weight: bold;
  line-height: 16px;
  width: 18px;
  height: 18px;
  text-align: center;
}
.shortcut-link .text {
  display: inline-block;
  margin-left: 10px;
  margin-top: -5px;
  color: #626262;
  font-weight: 600;
}
.shortcut-link:hover {
  text-decoration: none;
  color: #626262;
  transition: color 0.2s ease;
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  -ms-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
}
.headline {
  margin: 20px 0;
  padding: 5px 0 10px 0;
  border-bottom: 1px solid #eee;
  font-weight: 500;
}
.headline.dark {
  border-bottom: 1px solid #1a1a1a;
}
.headline .line {
  display: block;
  height: 3px;
  background: #3c8dbc;
  margin-top: 7px;
  margin-bottom: -10px;
  width: 50px;
}
.section-header {
  position: relative;
  text-align: center;
  font-weight: bold;
  margin: 30px 0;
}
.section-header span {
  width: 30%;
  display: block;
  margin: 0 auto;
  font-size: 22px;
  line-height: 40px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .section-header span {
    width: 100%;
    text-align: left;
    padding-left: 20px;
  }
}
.section-header hr {
  position: absolute;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #fff;
  width: 35%;
}
.section-header hr.right {
  right: 0px;
  top: 0px;
}
.chart-container {
  margin-bottom: 20px;
  width: 100%;
  height: 250px;
}
.notification-label {
  position: absolute;
  display: inline-block;
  background: #fc8675;
  width: 15px;
  height: 15px;
  padding: 2px;
  color: #fff;
  font-size: 9px;
  text-align: center;
  border-radius: 50em;
  -moz-border-radius: 50em;
  -webkit-border-radius: 50em;
  top: 7px;
  right: 5px;
  line-height: 9px;
  text-shadow: none;
}
#main-container {
  position: relative;
  min-height: 800px;
  padding-top: 45px;
  margin-left: 194px;
}
@media (max-width: 480px) {
  #main-container {
    min-width: 310px;
  }
}
@media (min-width: 481px) and (max-width: 550px) {
  #main-container {
    min-width: 471px;
  }
}
@media (min-width: 551px) and (max-width: 600px) {
  #main-container {
    min-width: 541px;
  }
}
@media (min-width: 601px) and (max-width: 670px) {
  #main-container {
    min-width: 591px;
  }
}
@media (max-width: 767px) {
  #main-container {
    left: 0;
    margin-left: 0;
  }
}
@media (min-width: 768px) and (max-width: 868px) {
  #main-container {
    margin-left: 90px;
    transition: margin-left 0.5s ease;
    -webkit-transition: margin-left 0.5s ease;
    -moz-transition: margin-left 0.5s ease;
    -ms-transition: margin-left 0.5s ease;
    -o-transition: margin-left 0.5s ease;
  }
}
#main-container.fade-out {
  opacity: 0;
  transform: translateX(-50px);
  -webkit-transform: translateX(-50px);
  -moz-transform: translateX(-50px);
  -ms-transform: translateX(-50px);
  -o-transform: translateX(-50px);
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
}
.container-left {
  position: absolute;
  left: 0;
  background: #000;
  height: 100%;
  width: 100px;
}
.theme-color,
.theme-pattern {
  display: inline-block;
  width: 25px;
  height: 25px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 5px 0 -5px 5px;
  border-radius: 1px;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
}
.theme-layout {
  cursor: pointer;
  color: #626262;
}
.theme-layout:hover,
.theme-layout:focus {
  text-decoration: none;
  color: #3c8dbc !important;
}
#scroll-to-top {
  position: fixed;
  cursor: pointer;
  bottom: -1000px;
  right: 3px;
  color: #fff;
  z-index: 1049;
  border-radius: 50em;
  -moz-border-radius: 50em;
  -webkit-border-radius: 50em;
  padding: 10px 14px;
  background: rgba(60, 141, 188, 0.5);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
#scroll-to-top:hover,
#scroll-to-top:focus {
  text-decoration: none;
  background: #3c8dbc;
  color: #fff;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
}
.main-header {
  padding: 20px;
}
.main-header .page-title {
  float: left;
}
.main-header .page-title span {
  display: block;
  color: #999;
  text-shadow: 0 1px 0 #fff;
  font-size: 12px;
  margin-top: 5px;
}
.main-header .page-stats {
  float: right;
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (max-width: 480px) {
  .main-header .page-stats {
    display: none;
  }
}
.main-header .page-stats li {
  float: left;
  display: block;
  padding: 0 20px;
  border-right: 1px solid #ccc;
}
.main-header .page-stats li:last-child {
  border-right: none;
}
.main-header .page-stats li .sparkline {
  float: right;
  margin-top: 5px;
}
@media (max-width: 979px) {
  .main-header .page-stats li .sparkline {
    display: none;
  }
}
.main-header .page-stats li .value {
  float: left;
  margin-right: 20px;
}
.main-header .page-stats li .value span {
  font-size: 11px;
  font-weight: 600;
  color: #bbb;
  text-shadow: 0 1px 0 #fff;
  text-transform: uppercase;
}
.main-header .page-stats li .value h4 {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  color: #777;
  margin: 5px 0;
}
.chart {
  display: inline-block;
}
svg {
  width: 100% !important;
}
.toolbar {
  float: right;
}
.toolbar > ul {
  list-style: none;
  display: block;
  margin: 0;
  margin-right: -15px;
}
.toolbar > ul > li {
  display: inline-block;
  float: right;
}
.toolbar > ul > li .btn {
  color: #626262;
  background: transparent;
  margin-top: -10px;
  padding: 10px 12px;
  margin-left: -4px;
  border-color: rgba(0, 0, 0, 0.2);
  border-width: 0px 0px 0px 1px;
  text-shadow: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}
.toolbar > ul > li .btn-group > .btn:first-child {
  border-radius: 0 4px 0 0;
  -moz-border-radius: 0 4px 0 0;
  -webkit-border-radius: 0 4px 0 0;
}
.toolbar > ul > li .dropdown-menu {
  right: 0;
  left: auto;
}
.toolbar .label {
  font-size: 11px;
}
.toolbar .progress {
  width: 120px;
  height: 10px;
  margin-top: 5px;
  margin-bottom: 0;
}
.toolbar .progress .progress-bar {
  line-height: 10px;
  font-size: 11px;
  font-weight: normal;
}
.toolbar .accordion-toggle.collapsed i:before {
  content: '\f078';
}
.toolbar .accordion-toggle i:before {
  content: '\f077';
}
.task-list li.removed {
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
  height: 0;
  overflow: hidden;
  border-top: 0px;
  -moz-transition: opacity 0.25s linear, padding-top 0.1s linear 0.25s, padding-bottom 0.1s linear 0.25s, border-top 0.1s linear 0.25s, height 0.1s linear 0.25s;
  -webkit-transition: opacity 0.25s linear, padding-top 0.1s linear 0.25s, padding-bottom 0.1s linear 0.25s, border-top 0.1s linear 0.25s, height 0.1s linear 0.25s;
  -o-transition: opacity 0.25s linear, padding-top 0.1s linear 0.25s, padding-bottom 0.1s linear 0.25s, border-top 0.1s linear 0.25s, height 0.1s linear 0.25s;
  transition: opacity 0.25s linear, padding-top 0.1s linear 0.25s, padding-bottom 0.1s linear 0.25s, border-top 0.1s linear 0.25s, height 0.1s linear 0.25s;
}
.task-list li.selected {
  text-decoration: line-through;
  color: #ccc;
  background: #f1f5fc;
}
.task-list li .task-del:hover,
.task-list li .task-del:focus {
  text-decoration: none;
}
.alert-animated {
  padding: 5px;
  border: 1px solid #f1f5fc;
  font-size: 12px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 20px;
  background-color: #f9f9f9;
}
.alert-inner {
  border: 1px solid #f1f5fc;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  background-color: #fff;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.alert-message {
  position: relative;
  text-shadow: 0 1px #fff;
  animation: text-moving 20s infinite;
  -webkit-animation: text-moving 20s infinite;
  -moz-animation: text-moving 20s infinite;
  -ms-animation: text-moving 20s infinite;
  -o-animation: text-moving 20s infinite;
}
.activity-icon {
  display: inline-block;
  background: #999;
  float: left;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.5) rgba(255, 255, 255, 0.5);
  width: 59px;
  height: 59px;
  font-size: 30px;
  color: #fff;
  text-align: center;
  border-radius: 50em;
  -moz-border-radius: 50em;
  -webkit-border-radius: 50em;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.activity-icon [class*="fa-"] {
  line-height: 55px;
}
.activity-icon.small {
  width: 42px;
  height: 42px;
  font-size: 20px;
}
.activity-icon.small [class*="fa-"] {
  line-height: 38px;
}
.img-demo {
  position: relative;
  float: left;
  background: #3a3a3a;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: auto;
  min-width: 40px;
  padding: 5px 10px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-size: 15px;
  border-radius: 4px;
}
.img-demo img {
  width: 40px;
  height: 40px;
}
.img-demo.product {
  float: none;
  margin-right: 0;
  width: 100%;
  max-width: 200px;
  height: 200px;
}
@media (min-width: 481px) and (max-width: 979px) {
  .img-demo.product {
    float: none;
    margin-right: 0;
    width: 100%;
    height: 180px;
  }
}
.img-demo.product [class*="fa-"] {
  line-height: 200px;
  font-size: 40px;
}
@media (min-width: 481px) and (max-width: 979px) {
  .img-demo.product [class*="fa-"] {
    line-height: 180px;
    font-size: 40px;
  }
}
.chat {
  list-style: none;
  margin: 0;
}
.chat li {
  margin: 15px 0;
}
.chat li:first-child {
  margin-top: 0;
}
.chat li.left .chat-body {
  margin-left: 70px;
  background-color: #fff;
}
.chat li.left .chat-body:before {
  position: absolute;
  top: 10px;
  left: -8px;
  display: inline-block;
  background: #fff;
  width: 16px;
  height: 16px;
  border-top: 1px solid #f1f5fc;
  border-left: 1px solid #f1f5fc;
  content: '';
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}
.chat li.right .chat-body {
  margin-right: 70px;
  background-color: #fff;
}
.chat li.right .chat-body:before {
  position: absolute;
  top: 10px;
  right: -8px;
  display: inline-block;
  background: #fff;
  width: 16px;
  height: 16px;
  border-top: 1px solid #f1f5fc;
  border-right: 1px solid #f1f5fc;
  content: '';
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
.chat li img {
  width: 45px;
  height: 45px;
  border-radius: 50em;
  -moz-border-radius: 50em;
  -webkit-border-radius: 50em;
}
.chat li .chat-body {
  position: relative;
  font-size: 11px;
  padding: 10px;
  border: 1px solid #f1f5fc;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.chat li .chat-body .header {
  padding-bottom: 5px;
  border-bottom: 1px solid #f1f5fc;
}
.chat li .chat-body p {
  margin: 0;
}
.chat-toolbar {
  padding: 7px 10px;
  border-bottom: 1px solid #f1f5fc;
  background: #fff;
}
.chat-toolbar .btn {
  display: inline-block;
  padding: 2px 7px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border: 1px solid #f1f5fc;
  color: #626262;
  background: transparent;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.chat-toolbar .btn:hover,
.chat-toolbar .btn:focus {
  text-decoration: none;
  color: #3c8dbc;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.image-wrapper {
  position: relative;
  display: block;
  overflow: hidden;
}
.image-wrapper img {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.image-wrapper:hover,
.image-wrapper.active {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
.image-wrapper:hover img,
.image-wrapper.active img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.image-wrapper:hover .image-overlay,
.image-wrapper.active .image-overlay {
  opacity: 0.9;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
}
.image-wrapper:hover .image-overlay .image-overlay-inner a,
.image-wrapper.active .image-overlay .image-overlay-inner a {
  opacity: 1;
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateY(0%);
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #3c8dbc;
  background-image: -moz-linear-gradient(top, #5fa4cc, #3c8dbc);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5fa4cc), to(#3c8dbc));
  background-image: -webkit-linear-gradient(top, #5fa4cc, #3c8dbc);
  background-image: -o-linear-gradient(top, #5fa4cc, #3c8dbc);
  background-image: linear-gradient(to bottom, #5fa4cc, #3c8dbc);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#5fa4cc, endColorstr=#3c8dbc, GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  border: 6px solid #296282;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.image-overlay.left {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
}
.image-overlay:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.image-overlay .image-info {
  color: #ffffff;
  position: absolute;
  padding: 15px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.image-overlay .image-info .image-time {
  position: absolute;
  bottom: 15px;
}
.image-overlay .image-info .image-like {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.ribbon-wrapper {
  position: absolute;
  width: 75px;
  height: 75px;
  overflow: hidden;
  top: -2px;
  right: -2px;
}
.ribbon-wrapper .ribbon-inner {
  display: block;
  position: relative;
  padding: 5px 0;
  color: #fff;
  font-size: 13px;
  line-height: 17px;
  font-weight: 600;
  text-align: center;
  width: 107px;
  top: 11px;
  left: -5px;
  text-shadow: 0 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
.ribbon-wrapper .ribbon-inner.blue {
  background: #5276b3;
}
.ribbon-wrapper .ribbon-inner .yellow {
  background: #faa732;
}
.ribbon-wrapper .ribbon-inner .green {
  background: #76b752;
}
.icons-list {
  list-style: none;
  margin: 0px;
}
.icons-list li {
  cursor: pointer;
  line-height: 32px;
  height: 32px;
  padding-left: 12px;
}
.zoomOverlay {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  background-position: center;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}
.carousel-custom .carousel-inner .item img {
  display: block;
  margin: 0 auto 20px;
}
.carousel-custom .carousel-indicators {
  left: 90%;
}
@media (max-width: 480px) {
  .carousel-custom .carousel-indicators {
    display: none;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .carousel-custom .carousel-indicators {
    top: 0;
  }
}
.carousel-custom .carousel-indicators li {
  background: #eee;
}
.carousel-custom .carousel-indicators li.active {
  background: #65cea7;
}
.error-heading {
  font-size: 180px;
  font-weight: normal;
  animation: bounceInDown 0.8s ease-out;
  -webkit-animation: bounceInDown 0.8s ease-out;
  -moz-animation: bounceInDown 0.8s ease-out;
  -ms-animation: bounceInDown 0.8s ease-out;
  -o-animation: bounceInDown 0.8s ease-out;
}
@media (max-width: 480px) {
  .error-heading {
    font-size: 160px;
  }
}
.map {
  width: 100%;
  height: 450px;
  margin-bottom: 30px;
}
footer {
  position: relative;
  padding: 10px 15px;
  background: #fefefe;
  color: #777777;
  margin-left: 194px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
footer.dark {
  background: #1a1a1a;
}
@media (max-width: 767px) {
  footer {
    left: 0;
    margin-left: 0;
  }
}
@media (min-width: 768px) and (max-width: 868px) {
  footer {
    margin-left: 90px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
}
footer h5 {
  color: #fff;
  margin-top: 20px;
  text-shadow: none;
}
footer .footer-brand {
  font-size: 18px;
  margin-right: 20px;
}
footer .footer-brand span {
  font-size: 16px;
}
footer p {
  display: inline-block;
}
footer hr {
  border-top: 1px solid #242424;
  border-bottom: 1px solid #575757;
  margin-bottom: 0;
}
@media (max-width: 480px) {
  footer hr {
    margin-bottom: 10px;
  }
}
.row.row-merge {
  margin: 0;
}
.row.row-merge [class*="col-"] {
  padding: 0;
}
.slider.slider-horizontal .slider-track {
  height: 5px;
}
.slider.slider-horizontal .slider-selection {
  background: #65cea7;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.slider.slider-horizontal .slider-handle {
  margin-top: -11px;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.slider.slider-vertical {
  margin-right: 10px;
}
.slider.slider-vertical .slider-track {
  width: 5px;
}
.slider.slider-vertical .slider-selection {
  background: #65cea7;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.slider.slider-vertical .slider-handle {
  margin-left: -11px;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.slider-handle {
  background: #fff;
  border: 1px solid #eee;
  opacity: 1;
  width: 25px;
  height: 25px;
}
.social-connect {
  display: inline-block;
  border: 1px solid #f1f5fc;
  background: #fff;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50em;
  -moz-border-radius: 50em;
  -webkit-border-radius: 50em;
  font-size: 16px;
  text-align: center;
  transition: all 0.4s linear;
  -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -ms-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
}
.social-connect:hover,
.social-connect:focus {
  text-decoration: none;
  color: #fff;
  border-color: transparent;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.search-options {
  max-width: 920px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
@media (max-width: 767px) {
  .search-options {
    text-align: center;
  }
}
.search-options .search-pager {
  float: right;
}
@media (max-width: 767px) {
  .search-options .search-pager {
    float: none;
    text-align: center;
    margin-top: 20px;
  }
}
.search-filter {
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 1px 0 0 #ffffff;
  -moz-box-shadow: 0 1px 0 0 #ffffff;
  -webkit-box-shadow: 0 1px 0 0 #ffffff;
}
.search-filter ul {
  padding: 0;
  margin: 0;
}
.search-filter ul li {
  display: inline-block;
  margin-right: 10px;
}
.search-filter ul li.active a {
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25) inset;
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.25) inset;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.25) inset;
}
.search-filter ul li a {
  display: block;
  padding: 10px 15px;
  color: #777777;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.search-filter ul li a:hover,
.search-filter ul li a:focus {
  color: #3c8dbc;
  text-decoration: none;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.search-container .search-header {
  padding-bottom: 5px;
  border-bottom: 1px solid #f1f5fc;
}
.loading-overlay {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
}
.loading-overlay.active {
  display: block;
}
.loading-overlay .loading-icon {
  position: absolute;
  top: 45%;
  left: 50%;
  color: #fff;
  animation: spin 0.8s infinite linear;
  -webkit-animation: spin 0.8s infinite linear;
  -moz-animation: spin 0.8s infinite linear;
  -ms-animation: spin 0.8s infinite linear;
  -o-animation: spin 0.8s infinite linear;
}
.facebook-hover:hover {
  background: #3b5998 !important;
}
.twitter-hover:hover {
  background: #00aced !important;
}
.google-plus-hover:hover {
  background: #d14836 !important;
}
.rss-hover:hover {
  background: #ff8300 !important;
}
.tumblr-hover:hover {
  background: #3b5998 !important;
}
.dribbble-hover:hover {
  background: #ea4c89 !important;
}
.linkedin-hover:hover {
  background: #007fb1 !important;
}
.pinterest-hover:hover {
  background: #e0242a !important;
}
.custom-grid {
  padding-bottom: 999px !important;
  margin-bottom: -999px !important;
}
.btn {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.btn.btn-transparent {
  background: transparent;
  border: none;
}
.btn.quick-btn {
  padding: 5px 20px;
  font-size: 40px;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
.btn.quick-btn span {
  display: block;
  font-size: 13px;
  font-weight: 600;
}
.btn.btn-primary {
  background: #424f63;
  border: 1px solid #2e3744;
}
.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary.active {
  background: #343e4e;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.btn.btn-info {
  background: #6bafbd;
  border: 1px solid #4c99a9;
}
.btn.btn-info:hover,
.btn.btn-info:focus,
.btn.btn-info:active,
.btn.btn-info.active {
  background: #52a2b2;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.btn.btn-success {
  background: #65cea7;
  border: 1px solid #3ec291;
}
.btn.btn-success:hover,
.btn.btn-success:focus,
.btn.btn-success:active,
.btn.btn-success.active {
  background: #4ac598;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.btn.btn-warning {
  background: #f3ce85;
  border: 1px solid #eebb57;
}
.btn.btn-warning:hover,
.btn.btn-warning:focus,
.btn.btn-warning:active,
.btn.btn-warning.active {
  background: #f0c164;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.btn.btn-danger {
  background: #fc8675;
  border: 1px solid #fb5a43;
}
.btn.btn-danger:hover,
.btn.btn-danger:focus,
.btn.btn-danger:active,
.btn.btn-danger.active {
  background: #fb6752;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.open .dropdown-toggle.btn-primary {
  background: #384354;
  border-color: #343e4e;
}
.open .dropdown-toggle.btn-info {
  background: #59a5b5;
  border-color: #52a2b2;
}
.open .dropdown-toggle.btn-success {
  background: #52c89c;
  border-color: #4ac598;
}
.open .dropdown-toggle.btn-warning {
  background: #f1c56e;
  border-color: #f0c164;
}
.open .dropdown-toggle.btn-danger {
  background: #fb705c;
  border-color: #fb6752;
}
.open > .dropdown-menu.slidedown {
  animation: slidedown 0.9s linear;
  -webkit-animation: slidedown 0.9s linear;
  -moz-animation: slidedown 0.9s linear;
  -ms-animation: slidedown 0.9s linear;
  -o-animation: slidedown 0.9s linear;
  overflow: hidden;
}
.dropdown-menu {
  font-size: 12px;
}
.dropdown-menu.dark {
  background: #1a1a1a;
}
.dropdown-menu.dark .divider {
  border-top: 1px solid #0a0c0e;
  border-bottom: 1px solid #212121;
}
.dropdown-menu.dark li {
  background: #1a1a1a;
}
.dropdown-menu.dark li a {
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.25);
}
.dropdown-menu.dark li a:hover,
.dropdown-menu.dark li a:focus {
  background: #3c8dbc;
}
.dropdown-menu li a {
  padding: 4px 20px;
}
.dropdown-menu li a:hover,
.dropdown-menu li a:focus {
  background: #3c8dbc;
  color: #fff;
}
.dropdown-menu li a img {
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 50em;
  -moz-border-radius: 50em;
  -webkit-border-radius: 50em;
}
.dropdown-menu li a .detail {
  float: left;
  margin-left: 10px;
  white-space: normal;
}
.dropdown-menu li a .detail p {
  margin-top: 0;
}
.dropdown-menu.message {
  width: 280px;
  padding: 0;
}
.dropdown-menu.message li {
  border-bottom: 1px solid #ddd;
}
.dropdown-menu.message li:first-child a {
  border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -webkit-border-radius: 2px 2px 0 0;
  background: #fff;
  color: #777;
}
.dropdown-menu.message li:first-child a:hover,
.dropdown-menu.message li:first-child a:focus {
  background: #fff;
  color: #777;
}
.dropdown-menu.message li:last-child {
  border: none;
}
.dropdown-menu.message li:last-child a {
  border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  -webkit-border-radius: 0 0 2px 2px;
  background: #fff;
  color: #777;
}
.dropdown-menu.message li a {
  padding: 10px;
}
.dropdown-menu.message li a p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dropdown-menu.message li a img {
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 50em;
  -moz-border-radius: 50em;
  -webkit-border-radius: 50em;
}
.dropdown-menu.message li a .detail {
  float: left;
  margin-left: 10px;
  white-space: normal;
}
.dropdown-menu.message li a:hover,
.dropdown-menu.message li a:focus {
  background-color: #efefef;
  color: #626262;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.dropdown-menu.task {
  top: 95%;
  padding: 0;
  width: 250px;
}
.dropdown-menu.task li {
  border-bottom: 1px solid #ddd;
}
.dropdown-menu.task li:first-child a {
  border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -webkit-border-radius: 2px 2px 0 0;
  background-color: #fff;
  color: #777;
}
.dropdown-menu.task li:first-child a:hover,
.dropdown-menu.task li:first-child a:focus {
  background: #fff;
  color: #777;
}
.dropdown-menu.task li:last-child {
  border: none;
}
.dropdown-menu.task li:last-child a {
  border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  -webkit-border-radius: 0 0 2px 2px;
  background-color: #fff;
  color: #777;
}
.dropdown-menu.task li a {
  padding: 10px;
  cursor: pointer;
}
.dropdown-menu.task li a:hover,
.dropdown-menu.task li a:focus {
  background-color: #eee;
  color: #777;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.dropdown-menu.task .progress {
  height: 10px;
  line-height: 10px;
  margin: 0;
}
.dropdown-menu.task .progress .progress-bar {
  font-size: 11px;
}
.dropdown-menu.notification {
  top: 95%;
  padding: 0;
  width: 250px;
}
.dropdown-menu.notification li {
  border-bottom: 1px solid #ddd;
}
.dropdown-menu.notification li:first-child a {
  border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -webkit-border-radius: 2px 2px 0 0;
  background-color: #fff;
  color: #777;
}
.dropdown-menu.notification li:first-child a:hover,
.dropdown-menu.notification li:first-child a:focus {
  background: #fff;
  color: #777;
}
.dropdown-menu.notification li:last-child {
  border: none;
}
.dropdown-menu.notification li:last-child a {
  border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  -webkit-border-radius: 0 0 2px 2px;
  background-color: #fff;
  color: #777;
}
.dropdown-menu.notification li a {
  padding: 10px;
  cursor: pointer;
  position: relative;
}
.dropdown-menu.notification li a:hover,
.dropdown-menu.notification li a:focus {
  background-color: #eee;
  color: #777;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.dropdown-menu.notification li a .notification-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  font-size: 14px;
}
.dropdown-menu.notification li a .time {
  position: absolute;
  right: 5px;
  top: 12px;
}
.dropdown-demo {
  display: inline-block;
  position: relative;
  height: 180px;
  width: 200px;
}
.dropdown-demo > .dropdown-menu {
  display: block;
  position: static;
  text-shadow: none;
}
.panel {
  position: relative;
  background: #ffffff;
}
.panel.panel-dark {
  background: #111111 !important;
  border-color: #060606 !important;
}
.panel.panel-dark .panel-heading {
  background: #111111 !important;
  border-color: #060606 !important;
}
.panel.panel-dark .panel-heading .tool-bar li a {
  color: #fff;
}
.panel.panel-dark .panel-footer {
  background: #111111 !important;
  border-color: #060606 !important;
}
.panel.panel-default {
  border-color: #f1f5fc;
}
.panel.panel-default .panel-heading + .panel-collapse .panel-body {
  border-top-color: #f1f5fc;
}
.panel.panel-default .panel-heading {
  background: #fff;
  color: #777777;
  border-color: #f1f5fc;
}
.panel.panel-default .panel-title a {
  font-size: 12px;
}
.panel.panel-default .panel-title a:hover,
.panel.panel-default .panel-title a:focus {
  text-decoration: none;
  color: #aaa;
}
.panel.panel-default .panel-footer {
  background: #fff;
  border-color: #f1f5fc;
  color: #777777;
}
.panel .tool-bar {
  float: right;
  list-style: none;
  margin-bottom: 0;
}
.panel .tool-bar li {
  display: inline-block;
  float: left;
}
.panel .tool-bar li:last-child a {
  margin-right: -15px;
}
.panel .tool-bar li a {
  display: block;
  padding: 10px 10px;
  margin: -10px 0;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.panel .tool-bar li a:hover {
  background-color: rgba(0, 0, 0, 0.02);
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.panel.bg-primary .panel-footer {
  border-color: #2e3744;
  background: rgba(20, 20, 20, 0.07);
}
.panel.bg-info .panel-footer {
  border-color: #4c99a9;
  background: rgba(20, 20, 20, 0.07);
}
.panel.bg-success .panel-footer {
  border-color: #3ec291;
  background: rgba(20, 20, 20, 0.07);
}
.panel.bg-warning .panel-footer {
  border-color: #eebb57;
  background: rgba(20, 20, 20, 0.07);
}
.panel.bg-danger .panel-footer {
  border-color: #fb5a43;
  background: rgba(20, 20, 20, 0.07);
}
.panel-group .panel-heading + .panel-collapse .panel-body {
  border-top-color: #f1f5fc;
}
.panel-stat1 {
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}
.panel-stat1:hover .value {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transition: transform 0.4s ease;
  -webkit-transition: -webkit-transform 0.4s ease;
  -moz-transition: -moz-transform 0.4s ease;
  -ms-transition: -ms-transform 0.4s ease;
  -o-transition: -o-transform 0.4s ease;
}
.panel-stat1:hover .title {
  transform: scale(0.85);
  -webkit-transform: scale(0.85);
  -moz-transform: scale(0.85);
  -ms-transform: scale(0.85);
  -o-transform: scale(0.85);
  transition: transform 0.4s ease;
  -webkit-transition: -webkit-transform 0.4s ease;
  -moz-transition: -moz-transform 0.4s ease;
  -ms-transition: -ms-transform 0.4s ease;
  -o-transition: -o-transform 0.4s ease;
}
.panel-stat1 .value {
  font-size: 60px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.panel-stat1 .title {
  font-size: 16px;
  margin-top: -15px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.panel-stat2 {
  cursor: pointer;
  overflow: hidden;
}
.panel-stat2:hover .value {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.panel-stat2:hover .title {
  transform: scale(0.85);
  -webkit-transform: scale(0.85);
  -moz-transform: scale(0.85);
  -ms-transform: scale(0.85);
  -o-transform: scale(0.85);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.panel-stat2:hover .stat-icon {
  transform: scale(1.5) rotate(60deg) translateX(30px) translateY(-15px);
  -webkit-transform: scale(1.5) rotate(60deg) translateX(30px) translateY(-15px);
  -moz-transform: scale(1.5) rotate(60deg) translateX(30px) translateY(-15px);
  -ms-transform: scale(1.5) rotate(60deg) translateX(30px) translateY(-15px);
  -o-transform: scale(1.5) rotate(60deg) translateX(30px) translateY(-15px);
  opacity: 0.4;
  transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
}
.panel-stat2 .stat-icon {
  display: inline-block;
  float: left;
  font-size: 50px;
  line-height: 83px;
  transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
}
.panel-stat2 .value {
  font-size: 60px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.panel-stat2 .title {
  font-size: 16px;
  margin-top: -15px;
  margin-right: 10px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.panel-stat3 {
  position: relative;
  overflow: hidden;
  padding: 25px 20px;
  margin-bottom: 20px;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
.panel-stat3 .stat-icon {
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: 30px;
  opacity: 0.3;
}
.panel-stat3 .refresh-button {
  position: absolute;
  top: 10px;
  right: 10px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  color: rgba(0, 0, 0, 0.3);
}
.panel-stat3 .refresh-button:hover {
  color: #fff;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.panel-overview {
  padding: 0;
}
.panel-overview:before,
.panel-overview:after {
  display: table;
  line-height: 0;
  content: "";
}
.panel-overview:after {
  clear: both;
}
.panel-overview .overview-icon {
  display: inline-block;
  width: 50%;
  float: left;
  text-align: center;
  padding: 10px;
  font-size: 70px;
}
.panel-overview .overview-value {
  display: inline-block;
  width: 50%;
  float: left;
  text-align: center;
  padding: 15px 10px 10px;
}
.panel-tab {
  background: #f9f9f9;
}
.panel-tab:before,
.panel-tab:after {
  display: table;
  line-height: 0;
  content: "";
}
.panel-tab:after {
  clear: both;
}
.tab-bar {
  list-style: none;
  margin: 0;
}
.tab-bar:before,
.tab-bar:after {
  display: table;
  line-height: 0;
  content: "";
}
.tab-bar:after {
  clear: both;
}
.tab-bar.right > li {
  float: right;
}
.tab-bar.right > li.active:first-child a {
  border-right: none;
}
.tab-bar.grey-tab {
  background: #e6e6e6;
}
.tab-bar.grey-tab li.active a {
  border: transparent;
  background: #f9f9f9;
}
.tab-bar.grey-tab li.active a:hover,
.tab-bar.grey-tab li.active a:focus {
  border: transparent;
}
.tab-bar.grey-tab li a {
  color: #bbb;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border: transparent;
  text-shadow: 0 1px 0 #fff;
}
.tab-bar.bg-primary li:not(.active) a,
.tab-bar.bg-info li:not(.active) a,
.tab-bar.bg-success li:not(.active) a,
.tab-bar.bg-warning li:not(.active) a,
.tab-bar.bg-danger li:not(.active) a {
  color: #fff;
  text-shadow: none;
  transition: color 0.2s ease;
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  -ms-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
}
.tab-bar.bg-primary li:not(.active) a:hover,
.tab-bar.bg-info li:not(.active) a:hover,
.tab-bar.bg-success li:not(.active) a:hover,
.tab-bar.bg-warning li:not(.active) a:hover,
.tab-bar.bg-danger li:not(.active) a:hover,
.tab-bar.bg-primary li:not(.active) a:focus,
.tab-bar.bg-info li:not(.active) a:focus,
.tab-bar.bg-success li:not(.active) a:focus,
.tab-bar.bg-warning li:not(.active) a:focus,
.tab-bar.bg-danger li:not(.active) a:focus {
  color: #eee;
  transition: color 0.2s ease;
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  -ms-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
}
.tab-bar > li {
  display: inline-block;
  float: left;
  margin-bottom: -1px;
}
.tab-bar > li.active:first-child a {
  border-left: none;
}
.tab-bar > li.active a {
  background: #fff;
  color: #777;
}
.tab-bar > li a {
  display: block;
  padding: 10px;
  color: #ccc;
  text-shadow: 0 1px #fff;
  transition: color 0.2s ease;
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  -ms-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
}
.tab-bar > li a:hover,
.tab-bar > li a:focus {
  text-decoration: none;
  color: #777;
  transition: color 0.2s ease;
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  -ms-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
}
.wizard-steps {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: 0;
}
.wizard-steps:before,
.wizard-steps:after {
  display: table;
  line-height: 0;
  content: "";
}
.wizard-steps:after {
  clear: both;
}
.wizard-steps li {
  display: inline-block;
  position: relative;
  float: left;
  padding: 0 5px 0 20px;
}
.wizard-steps li.active {
  background: #65cea7;
}
.wizard-steps li.active:after {
  border-left-color: #65cea7;
}
.wizard-steps li.active a {
  color: #fff;
  font-weight: bold;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.wizard-steps li a {
  display: block;
  padding: 10px;
  text-align: center;
  color: #ccc;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.wizard-steps li a:hover,
.wizard-steps li a:focus {
  text-decoration: none;
}
.wizard-steps li:before,
.wizard-steps li:after {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  top: 0;
  right: -36px;
  position: absolute;
  pointer-events: none;
  bottom: 100%;
}
.wizard-steps li:after {
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #f9f9f9;
  border-width: 18px;
  z-index: 2;
}
.wizard-steps li:before {
  border-color: rgba(194, 225, 245, 0);
  border-left-color: #eee;
  border-width: 20px;
  right: -40px;
  top: -2px;
  z-index: 1;
}
.tab-left:before,
.tab-left:after {
  display: table;
  line-height: 0;
  content: "";
}
.tab-left:after {
  clear: both;
}
.tab-left .tab-bar {
  float: left;
}
.tab-left .tab-bar > li {
  display: block;
  float: none;
  margin-right: -1px;
  border-right: 1px solid #eee;
}
.tab-left .tab-bar > li.active {
  border-right: 1px solid #fff;
  z-index: 2;
}
.tab-left .tab-bar > li.active:first-child a {
  border-top: none;
}
.tab-left .tab-bar > li.active a {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.tab-left .tab-content {
  overflow: auto;
  padding: 15px 20px;
  border-left: 1px solid #eee;
}
.tab-right:before,
.tab-right:after {
  display: table;
  line-height: 0;
  content: "";
}
.tab-right:after {
  clear: both;
}
.tab-right .tab-bar {
  float: right;
}
.tab-right .tab-bar > li {
  display: block;
  float: none;
  margin-left: -1px;
  border-left: 1px solid #eee;
}
.tab-right .tab-bar > li.active {
  border-left: 1px solid #fff;
  z-index: 2;
}
.tab-right .tab-bar > li.active:first-child a {
  border-top: none;
}
.tab-right .tab-bar > li.active a {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.tab-right .tab-content {
  overflow: auto;
  padding: 15px 20px;
  border-right: 1px solid #eee;
}
.table.table-vertical-center th,
.table.table-vertical-center td {
  vertical-align: middle;
}
.table thead > tr {
  border: 1px solid #eee;
}
.table thead > tr > th {
  border-color: #eee;
}
.table tbody > tr {
  border: 1px solid #eee;
}
.table tbody > tr.selected > td {
  background: #f1f5fc;
}
.table tbody > tr > td {
  border-color: #eee;
}
table th.text-center,
table td.text-center {
  text-align: center;
}
table th.text-right,
table td.text-right {
  text-align: right;
}
table.table-borderless th,
table.table-borderless td {
  border: none !important;
}
/* Datatables */
table.dataTable tr.odd td.sorting_1 {
  background-color: #f9f9f9;
}
table.dataTable tr.even td.sorting_1 {
  background-color: #fff;
}
table.dataTable tr.odd {
  background-color: #f9f9f9;
}
.paging_full_numbers {
  margin-top: 5px;
}
.paging_full_numbers .ui-button {
  border: 1px solid #eee;
}
.paging_full_numbers .ui-state-disabled {
  cursor: default !important;
  opacity: 0.35;
}
table.dataTable thead th .DataTables_sort_wrapper span {
  right: 6px !important;
}
table.dataTable thead th .DataTables_sort_wrapper span:before {
  font-family: FontAwesome;
  color: #777;
}
table.dataTable thead th .DataTables_sort_wrapper span.ui-icon-carat-2-n-s:before {
  content: "\f0dc";
}
table.dataTable thead th .DataTables_sort_wrapper span.ui-icon-triangle-1-n {
  top: 60% !important;
}
table.dataTable thead th .DataTables_sort_wrapper span.ui-icon-triangle-1-n:before {
  content: "\f0de";
}
table.dataTable thead th .DataTables_sort_wrapper span.ui-icon-triangle-1-s {
  top: 30% !important;
}
table.dataTable thead th .DataTables_sort_wrapper span.ui-icon-triangle-1-s:before {
  content: "\f0dd";
}
form.form-border .form-group {
  margin-bottom: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f1f5fc;
}
form.form-border .form-group:first-child {
  padding-top: 0;
}
form.form-border .form-group:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.input-icon input[type="text"] {
  padding-left: 20px;
}
label,
input,
button,
select,
textarea.form-control {
  font-size: 12px;
}
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  font-size: 12px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  margin: 0;
}
textarea.form-control {
  margin: 0;
}
textarea.form-control:focus,
select[multiple]:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: rgba(250, 167, 34, 0.8);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(250, 167, 34, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(250, 167, 34, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(250, 167, 34, 0.6);
}
input[type="text"].dial {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.select-box {
  display: inline-block;
  list-style: none;
  margin: 0;
  background: #fff;
  padding: 5px;
  width: 40%;
  height: 200px !important;
  font-size: 12px;
}
.select-box-option {
  position: absolute;
  left: 50%;
  top: 40%;
  margin-left: -50px;
  display: inline-block;
  height: 200px;
  width: 100px;
  text-align: center;
}
.help-btn {
  border-radius: 50em;
  -moz-border-radius: 50em;
  -webkit-border-radius: 50em;
  padding: 0 6px;
}
.upload-file {
  position: relative;
  height: 20px;
  padding: 4px 6px;
  line-height: 20px;
}
.upload-file input[type="file"] {
  position: absolute;
  opacity: 0;
}
.upload-file label {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  max-height: 28px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}
.upload-file label:before {
  display: inline-block;
  content: attr(data-title);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 8px;
  line-height: 26px;
  text-align: center;
  border-left: 1px solid #ccc;
  border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -webkit-border-radius: 0 4px 4px 0;
  background-color: #fff;
}
.upload-file label [class*="icon-"] {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px 0 0 4px;
  padding: 5px;
  line-height: 13px;
  color: #fff;
  width: auto;
}
.upload-file label span {
  display: inline-block;
  height: 26px;
  white-space: nowrap;
  overflow: hidden;
  line-height: 26px;
  color: #777;
  padding-left: 10px;
}
.upload-file label span:before {
  content: attr(data-title);
}
.form-horizontal .label-checkbox {
  padding-top: 5px;
}
input[type="radio"],
input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 15px;
  height: 15px;
  z-index: 2;
  cursor: pointer;
}
input[type="radio"] {
  margin-top: 0;
}
input[type="checkbox"]:checked + .custom-checkbox,
input[type="radio"]:checked + .custom-radio {
  background-color: #f5f8fc;
}
input[type="checkbox"] + .custom-checkbox,
input[type="checkbox"]:disabled + .custom-checkbox,
input[type="radio"] + .custom-radio,
input[type="radio"]:disabled + .custom-radio {
  display: inline-block;
  position: relative;
  line-height: 13px;
  background-color: #fafafa;
  margin-right: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
input[type="checkbox"]:hover + .custom-checkbox,
input[type="radio"]:hover + .custom-radio {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
input[type="checkbox"] + .custom-checkbox:before {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
input[type="checkbox"]:disabled + .custom-checkbox,
input[type="radio"]:disabled + .custom-radio {
  background-color: #ccc;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
input[type="checkbox"] + .custom-checkbox,
input[type="radio"] + .custom-radio {
  display: inline-block;
  font-family: fontAwesome;
  font-size: 12px;
  content: "";
  border: 1px solid #ccc;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  text-align: center;
  width: 17px;
  height: 17px;
  line-height: 15px;
  vertical-align: middle;
}
input[type="checkbox"]:checked + .custom-checkbox:before {
  content: '\f00c';
  border-color: rgba(0, 0, 0, 0.2);
  font-size: 12px;
  color: #7a7a7a;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  text-shadow: 0 1px #ffffff;
}
input[type="radio"] + .custom-radio,
input[type="radio"]:disabled + .custom-radio {
  border-radius: 50em;
  -moz-border-radius: 50em;
  -webkit-border-radius: 50em;
  line-height: 0px;
}
input[type="radio"] + .custom-radio:before {
  border-radius: 50em;
  -moz-border-radius: 50em;
  -webkit-border-radius: 50em;
}
input[type="radio"]:checked + .custom-radio:before {
  content: '';
  position: absolute;
  display: block;
  width: 11px;
  height: 11px;
  top: 2px;
  left: 2px;
  background-color: #777;
  border-color: #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  text-shadow: 0 1px #ffffff;
}
input[type="radio"]:checked + .custom-radio.blue:before {
  color: #0099CC;
}
input[type="checkbox"]:disabled:active + .custom-checkbox,
input[type="radio"]:disabled:active + .custom-radio {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
input[type="checkbox"]:active + .custom-checkbox,
input[type="radio"]:active + .custom-radio {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.1);
}
input[type="radio"]:active + .custom-radio {
  border-radius: 50em;
  -moz-border-radius: 50em;
  -webkit-border-radius: 50em;
}
.label-checkbox,
.label-radio {
  display: block;
  cursor: auto;
  margin-bottom: 0;
}
.label-radio {
  padding-top: 6px;
}
.label-checkbox.inline,
.label-radio.inline {
  display: inline-block;
  margin-right: 5px;
}
.label-radio.inline {
  padding-top: 5px;
}
#overlay {
  background: #000000;
  left: 0;
  right: 0;
  top: 0;
  bottom: -100px;
  position: fixed;
  z-index: 9999;
  opacity: 1;
}
#overlay.transparent {
  background: rgba(0, 0, 0, 0.5);
}
.overlay-inner {
  position: absolute;
  top: 40%;
  left: 45%;
}
@media (max-width: 767px) {
  .overlay-inner {
    left: 35%;
  }
}
@media (max-width: 600px) {
  .overlay-inner {
    left: 25%;
  }
}
@media (max-width: 480px) {
  .overlay-inner {
    left: 17%;
  }
}
@media (max-width: 400px) {
  .overlay-inner {
    left: 5%;
  }
}
@media (max-width: 767px) {
  .overlay-inner.style2 {
    left: 45%;
  }
}
@media (max-width: 480px) {
  .overlay-inner.style2 {
    left: 42%;
  }
}
#fountainTextG {
  width: 240px;
}
.fountainTextG {
  color: #000000;
  font-size: 25px;
  text-decoration: none;
  font-weight: normal;
  font-style: normal;
  float: left;
  -moz-animation-name: bounce_fountainTextG;
  -moz-animation-duration: 1.82s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-direction: linear;
  -moz-transform: scale(0.5);
  -webkit-animation-name: bounce_fountainTextG;
  -webkit-animation-duration: 1.82s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: linear;
  -webkit-transform: scale(0.5);
  -ms-animation-name: bounce_fountainTextG;
  -ms-animation-duration: 1.82s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-direction: linear;
  -ms-transform: scale(0.5);
  -o-animation-name: bounce_fountainTextG;
  -o-animation-duration: 1.82s;
  -o-animation-iteration-count: infinite;
  -o-animation-direction: linear;
  -o-transform: scale(0.5);
  animation-name: bounce_fountainTextG;
  animation-duration: 1.82s;
  animation-iteration-count: infinite;
  animation-direction: linear;
  transform: scale(0.5);
}
#fountainTextG_1 {
  -moz-animation-delay: 0.52s;
  -webkit-animation-delay: 0.52s;
  -ms-animation-delay: 0.52s;
  -o-animation-delay: 0.52s;
  animation-delay: 0.52s;
}
#fountainTextG_2 {
  -moz-animation-delay: 0.65s;
  -webkit-animation-delay: 0.65s;
  -ms-animation-delay: 0.65s;
  -o-animation-delay: 0.65s;
  animation-delay: 0.65s;
}
#fountainTextG_3 {
  -moz-animation-delay: 0.78s;
  -webkit-animation-delay: 0.78s;
  -ms-animation-delay: 0.78s;
  -o-animation-delay: 0.78s;
  animation-delay: 0.78s;
}
#fountainTextG_4 {
  -moz-animation-delay: 0.91s;
  -webkit-animation-delay: 0.91s;
  -ms-animation-delay: 0.91s;
  -o-animation-delay: 0.91s;
  animation-delay: 0.91s;
}
#fountainTextG_5 {
  -moz-animation-delay: 1.04s;
  -webkit-animation-delay: 1.04s;
  -ms-animation-delay: 1.04s;
  -o-animation-delay: 1.04s;
  animation-delay: 1.04s;
}
#fountainTextG_6 {
  -moz-animation-delay: 1.17s;
  -webkit-animation-delay: 1.17s;
  -ms-animation-delay: 1.17s;
  -o-animation-delay: 1.17s;
  animation-delay: 1.17s;
}
#fountainTextG_7 {
  -moz-animation-delay: 1.3s;
  -webkit-animation-delay: 1.3s;
  -ms-animation-delay: 1.3s;
  -o-animation-delay: 1.3s;
  animation-delay: 1.3s;
}
#fountainTextG_8 {
  -moz-animation-delay: 1.43s;
  -webkit-animation-delay: 1.43s;
  -ms-animation-delay: 1.43s;
  -o-animation-delay: 1.43s;
  animation-delay: 1.43s;
}
#fountainTextG_9 {
  -moz-animation-delay: 1.56s;
  -webkit-animation-delay: 1.56s;
  -ms-animation-delay: 1.56s;
  -o-animation-delay: 1.56s;
  animation-delay: 1.56s;
}
#fountainTextG_10 {
  -moz-animation-delay: 1.69s;
  -webkit-animation-delay: 1.69s;
  -ms-animation-delay: 1.69s;
  -o-animation-delay: 1.69s;
  animation-delay: 1.69s;
}
@-moz-keyframes bounce_fountainTextG {
  0% {
    -moz-transform: scale(1);
    color: #000000;
  }
  100% {
    -moz-transform: scale(0.5);
    color: #FFFFFF;
  }
}
@-webkit-keyframes bounce_fountainTextG {
  0% {
    -webkit-transform: scale(1);
    color: #000000;
  }
  100% {
    -webkit-transform: scale(0.5);
    color: #FFFFFF;
  }
}
@-ms-keyframes bounce_fountainTextG {
  0% {
    -ms-transform: scale(1);
    color: #000000;
  }
  100% {
    -ms-transform: scale(0.5);
    color: #FFFFFF;
  }
}
@-o-keyframes bounce_fountainTextG {
  0% {
    -o-transform: scale(1);
    color: #000000;
  }
  100% {
    -o-transform: scale(0.5);
    color: #FFFFFF;
  }
}
@keyframes bounce_fountainTextG {
  0% {
    transform: scale(1);
    color: #000000;
  }
  100% {
    transform: scale(0.5);
    color: #FFFFFF;
  }
}
#followingBallsG {
  position: relative;
  width: 256px;
  height: 20px;
}
.followingBallsG {
  background-color: #000000;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  animation-name: bounce_followingBallsG;
  -webkit-animation-name: bounce_followingBallsG;
  -moz-animation-name: bounce_followingBallsG;
  -ms-animation-name: bounce_followingBallsG;
  -o-animation-name: bounce_followingBallsG;
  animation-duration: 3.2s;
  -webkit-animation-duration: 3.2s;
  -moz-animation-duration: 3.2s;
  -ms-animation-duration: 3.2s;
  -o-animation-duration: 3.2s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-direction: linear;
  -webkit-animation-direction: linear;
  -moz-animation-direction: linear;
  -ms-animation-direction: linear;
  -o-animation-direction: linear;
}
#followingBallsG_1 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
}
#followingBallsG_2 {
  animation-delay: 0.32s;
  -webkit-animation-delay: 0.32s;
  -moz-animation-delay: 0.32s;
  -ms-animation-delay: 0.32s;
  -o-animation-delay: 0.32s;
}
#followingBallsG_3 {
  animation-delay: 0.64s;
  -webkit-animation-delay: 0.64s;
  -moz-animation-delay: 0.64s;
  -ms-animation-delay: 0.64s;
  -o-animation-delay: 0.64s;
}
#followingBallsG_4 {
  animation-delay: 0.96s;
  -webkit-animation-delay: 0.96s;
  -moz-animation-delay: 0.96s;
  -ms-animation-delay: 0.96s;
  -o-animation-delay: 0.96s;
}
.login-wrapper {
  position: absolute;
  top: 50px;
  width: 100%;
  margin-left: 0;
  animation: bounceInDownSmall 1s ease;
  -webkit-animation: bounceInDownSmall 1s ease;
  -moz-animation: bounceInDownSmall 1s ease;
  -ms-animation: bounceInDownSmall 1s ease;
  -o-animation: bounceInDownSmall 1s ease;
}
.login-wrapper.fadeOutUp {
  animation: fadeOutUp 0.5s ease-out;
  -webkit-animation: fadeOutUp 0.5s ease-out;
  -moz-animation: fadeOutUp 0.5s ease-out;
  -ms-animation: fadeOutUp 0.5s ease-out;
  -o-animation: fadeOutUp 0.5s ease-out;
  opacity: 0;
}
.login-wrapper .login-widget {
  margin: 0 auto;
  width: 400px;
  margin-top: 20px;
}
@media (max-width: 480px) {
  .login-wrapper .login-widget {
    width: 100%;
  }
}
.login-wrapper .login-widget .widget {
  box-shadow: 0 1px 15px -4px #000000;
  -moz-box-shadow: 0 1px 15px -4px #000000;
  -webkit-box-shadow: 0 1px 15px -4px #000000;
}
.blog-container a:not(.btn) {
  color: #999;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}
.blog-container a:not(.btn):hover,
.blog-container a:not(.btn):focus {
  color: #777;
  text-decoration: none;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}
.blog-container .post-like {
  float: right;
  cursor: pointer;
}
.blog-container .post-like.liked {
  color: #3c8dbc;
}
.blog-container .post-like.liked [class*="icon-"]:before {
  content: '\f004';
}
.blog-container .post-like:hover {
  text-decoration: none;
  color: #3c8dbc;
}
.popular-post img {
  width: 50px;
  height: 50px;
}
.category {
  padding: 0;
  list-style: none;
  margin-bottom: 30px;
}
.category li {
  border-bottom: 1px solid #ccc;
}
.category li a {
  display: block;
  padding: 10px;
  color: #777;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.category li a:hover,
.category li a:focus {
  color: #3c8dbc;
  text-decoration: none;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.blog-tag {
  display: inline-block;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #fff;
  color: #777;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
  margin: 2px 2px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.blog-tag:hover {
  text-decoration: none;
  color: #fff;
  background-color: #3c8dbc;
  text-shadow: none;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.photo-stream {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}
.photo-stream li {
  display: inline-block;
}
.photo-stream li a {
  display: inline-block;
  border: 1px solid #3c8dbc;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.photo-stream li a:hover,
.photo-stream li a:focus {
  box-shadow: 0 0 0 10px #3c8dbc inset;
  -moz-box-shadow: 0 0 0 10px #3c8dbc inset;
  -webkit-box-shadow: 0 0 0 10px #3c8dbc inset;
  opacity: 0.5;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.photo-stream li a img {
  width: 60px;
  height: 60px;
  float: left;
}
@media (max-width: 480px) {
  .share-blog {
    text-align: center;
  }
  .share-blog .pull-left {
    float: none !important;
  }
  .share-blog .pull-right {
    float: none !important;
  }
}
@media (max-width: 480px) {
  .share-blog span {
    display: block;
  }
}
.comment-list li {
  margin-bottom: 30px;
}
.comment-list li .media-heading {
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}
.comment-list li .media-heading a {
  color: #777;
  font-weight: 500;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
@media (max-width: 480px) {
  .comment-list li .media-heading a {
    display: block;
  }
}
.comment-list li .media-heading a:hover,
.comment-list li .media-heading a:focus {
  text-decoration: none;
  color: #3c8dbc;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.comment-list li .media-heading small {
  float: right;
}
@media (max-width: 480px) {
  .comment-list li .media-heading small {
    float: none;
  }
}
.timeline-wrapper {
  position: relative;
  padding-left: 10px;
  margin-bottom: 20px;
}
.timeline-wrapper:before {
  content: '';
  display: block;
  position: absolute;
  left: 40px;
  top: 0px;
  bottom: 0px;
  border: solid #e2e3e7;
  background-color: #e7eaef;
  width: 4px;
}
.timeline-wrapper .timeline-start {
  position: relative;
  display: inline-block;
}
.timeline-wrapper .timeline-icon {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50em;
  -moz-border-radius: 50em;
  -webkit-border-radius: 50em;
  margin: 0 auto;
  font-size: 20px;
  line-height: 40px;
}
.timeline-wrapper .timeline-info {
  position: relative;
  float: left;
  width: 65px;
  text-align: center;
}
.timeline-wrapper .time {
  margin-top: 10px;
  background: #fff;
  border: 1px solid #f1f5fc;
  padding: 5px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  color: #999;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
}
.timeline-wrapper .timeline-panel {
  background: #fff;
  margin-left: 75px;
  border: 1px solid #f1f5fc;
}
.timeline-wrapper .timeline-panel img {
  width: 200px;
}
.timeline-wrapper .timeline-date {
  display: inline-block;
  position: relative;
  margin-top: 10px;
  background: #3c8dbc;
  border: 1px solid #3c8dbc;
  padding: 5px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  color: #fff;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  font-weight: bold;
}
.menu-grid .menu-header {
  border-bottom: 1px solid #3c485a;
  box-shadow: 0 1px 0 #48566c;
  -moz-box-shadow: 0 1px 0 #48566c;
  -webkit-box-shadow: 0 1px 0 #48566c;
  padding: 10px 20px;
  font-size: 18px;
}
.menu-grid .menu-header .btn {
  color: #fff;
}
.menu-grid .navbar-toggle .icon-bar {
  background: #fff;
}
.menu-grid .inbox-menu {
  margin-bottom: 0;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
@media (min-width: 768px) {
  .menu-grid .inbox-menu {
    height: auto !important;
  }
}
@media (max-width: 767px) {
  .menu-grid .inbox-menu {
    max-height: 0;
  }
  .menu-grid .inbox-menu.menu-display {
    max-height: 350px;
    height: auto;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
}
.menu-grid .inbox-menu > li {
  border-bottom: 1px solid #3c485a;
  box-shadow: 0 1px 0 #48566c;
  -moz-box-shadow: 0 1px 0 #48566c;
  -webkit-box-shadow: 0 1px 0 #48566c;
}
.menu-grid .inbox-menu > li.active > a {
  background: #384354;
}
.menu-grid .inbox-menu > li.active > a:hover,
.menu-grid .inbox-menu > li.active > a:focus {
  background: #384354;
}
.menu-grid .inbox-menu > li > a {
  display: block;
  color: #fff;
  padding: 10px 20px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.menu-grid .inbox-menu > li > a:hover,
.menu-grid .inbox-menu > li > a:focus {
  background: #3c485a;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.menu-grid .inbox-menu > li .submenu > li {
  border-bottom: 1px solid #445166;
}
.menu-grid .inbox-menu > li .submenu > li:last-child {
  border-bottom: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.menu-grid .inbox-menu > li .submenu > li.active > a {
  background: #343e4e;
}
.menu-grid .inbox-menu > li .submenu > li.active > a:hover,
.menu-grid .inbox-menu > li .submenu > li.active > a:focus {
  background: #343e4e;
}
.menu-grid .inbox-menu > li .submenu > li > a {
  display: block;
  color: #fff;
  padding: 10px 20px;
  background: #3e4a5d;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.menu-grid .inbox-menu > li .submenu > li > a:hover,
.menu-grid .inbox-menu > li .submenu > li > a:focus {
  background: #384354;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.inbox-panel {
  margin-bottom: 0;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}
.inbox-panel .inbox-item {
  cursor: pointer;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.inbox-panel .inbox-item:hover {
  background: #f7f7f7;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.inbox-panel .inbox-item.selected {
  color: #ccc;
  background: #f1f5fc;
  border: 1px solid #e4ecf9;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.inbox-panel .inbox-item.selected:hover {
  background: #f1f5fc;
  color: #ccc;
}
.inbox-panel .inbox-item .starred {
  color: #f3ce85;
}
.inbox-panel .inbox-item .not-starred:hover {
  color: #f3ce85;
}
.inbox-panel .inbox-item .from {
  display: inline-block;
  width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
  white-space: nowrap;
  font-weight: bold;
  margin-left: 10px;
  height: 19px;
}
.inbox-panel .inbox-item .detail {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  text-overflow: ellipsis;
  width: 50%;
  height: 19px;
}
@media (max-width: 480px) {
  .inbox-panel .inbox-item .detail {
    width: 65%;
  }
}
.inbox-panel .inbox-item .attachment {
  margin-right: 15px;
}
.inbox-panel .panel-footer .pagination {
  margin: 2px 0 0;
}
.inbox-panel .panel-footer .pagination li a {
  padding: 0 10px;
  background: transparent;
  border: none;
}
.inbox-panel .panel-footer .pagination li a .large {
  font-size: 140%;
}
.inbox-action {
  font-weight: bold;
  background: #fff;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.inbox-action.active {
  background: #65cea7;
  color: #fff;
}
.inbox-action.active a {
  color: #fff;
}
.inbox-action.active a:hover,
.inbox-action.active a:focus {
  background-color: #65cea7;
}
.inbox-action a {
  display: block;
  padding: 10px;
  color: #777;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.inbox-action a:hover,
.inbox-action a:focus {
  text-decoration: none;
  background-color: #f2f2f2;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.gallery-container .gallery-item {
  display: inline-block;
  width: 33.33333%;
  float: left;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .gallery-container .gallery-item {
    width: 50%;
  }
}
@media (max-width: 600px) {
  .gallery-container .gallery-item {
    width: 100%;
  }
}
.gallery-container .gallery-item .image-wrapper {
  width: 100%;
  padding-top: 60%;
}
.gallery-container .gallery-item .image-wrapper img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.pricing-widget {
  position: relative;
  border: 1px solid #f1f5fc;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  margin: 20px 0;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.pricing-widget.active {
  z-index: 2;
}
.pricing-widget:not(.pricing-title).active,
.pricing-widget:not(.pricing-title):hover {
  box-shadow: 0 3px 25px -4px rgba(0, 0, 0, 0.9);
  -moz-box-shadow: 0 3px 25px -4px rgba(0, 0, 0, 0.9);
  -webkit-box-shadow: 0 3px 25px -4px rgba(0, 0, 0, 0.9);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
.pricing-widget:not(.pricing-title).active .pricing-cost,
.pricing-widget:not(.pricing-title):hover .pricing-cost {
  color: #999;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.pricing-widget .pricing-head {
  background: #fff;
  color: #777;
  border-bottom: 1px solid #f1f5fc;
  padding: 6px 20px;
  font-weight: 600;
  font-size: 13px;
  min-height: 31px;
}
.pricing-widget .pricing-body {
  background: #fff;
  color: #626262;
}
.pricing-widget .pricing-cost {
  background: #fff;
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid #f1f5fc;
  color: #777777;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  font-size: 18px;
  min-height: 125px;
}
.pricing-widget .pricing-cost strong {
  font-size: 30px;
}
.pricing-widget .pricing-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.pricing-widget .pricing-list [class*="icon-"] {
  margin-right: 30px;
}
.pricing-widget .pricing-list.text-center li {
  text-align: center;
}
.pricing-widget .pricing-list li {
  padding: 10px;
  border-bottom: 1px solid #f1f5fc;
}
.pricing-widget .pricing-list li:last-child {
  min-height: 84px;
  padding-top: 40px;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-bottom: none;
}
.pricing-widget .pricing-list li:last-child [class*="icon-"] {
  margin-right: 5px;
}
#wrapper .chat-wrapper {
  position: relative;
}
#wrapper .chat-wrapper .chat-sidebar {
  position: fixed;
  width: 250px;
  left: 194px;
  top: 45px;
  bottom: 0;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 50px;
  border-bottom: 1px solid #eee;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
@media (max-width: 767px) {
  #wrapper .chat-wrapper .chat-sidebar {
    left: -250px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
}
@media (max-width: 868px) and (min-width: 768px) {
  #wrapper .chat-wrapper .chat-sidebar {
    left: 90px !important;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
}
#wrapper .chat-wrapper .chat-sidebar .friend-list {
  list-style: none;
}
#wrapper .chat-wrapper .chat-sidebar .friend-list li {
  border-bottom: 1px solid #eee;
}
#wrapper .chat-wrapper .chat-sidebar .friend-list li.active a {
  background-color: #f1f5fc;
}
#wrapper .chat-wrapper .chat-sidebar .friend-list li a {
  position: relative;
  display: block;
  padding: 10px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
#wrapper .chat-wrapper .chat-sidebar .friend-list li a img {
  float: left;
  width: 45px;
  height: 45px;
  margin-right: 10px;
}
#wrapper .chat-wrapper .chat-sidebar .friend-list li a .friend-name {
  color: #777;
}
#wrapper .chat-wrapper .chat-sidebar .friend-list li a .friend-name:hover {
  color: #777;
}
#wrapper .chat-wrapper .chat-sidebar .friend-list li a .last-message {
  width: 65%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#wrapper .chat-wrapper .chat-sidebar .friend-list li a .time {
  position: absolute;
  top: 10px;
  right: 8px;
}
#wrapper .chat-wrapper .chat-sidebar .friend-list li a .chat-alert {
  position: absolute;
  right: 8px;
  top: 27px;
  font-size: 10px;
  padding: 3px 5px;
}
#wrapper .chat-wrapper .chat-sidebar .friend-list li a:hover {
  background-color: #eee;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
#wrapper .chat-wrapper .chat-inner {
  position: fixed;
  top: 40px;
  right: 0;
  left: 444px;
  height: 100%;
  overflow-y: auto;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
@media (max-width: 767px) {
  #wrapper .chat-wrapper .chat-inner {
    left: 0;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
}
@media (max-width: 868px) and (min-width: 768px) {
  #wrapper .chat-wrapper .chat-inner {
    left: 340px !important;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
}
#wrapper .chat-wrapper .chat-inner .chat-header {
  position: fixed;
  top: 45px;
  left: 444px;
  right: 0;
  padding: 0 10px;
  border-bottom: 1px solid #eee;
  z-index: 10;
  height: 40px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
@media (max-width: 767px) {
  #wrapper .chat-wrapper .chat-inner .chat-header {
    left: 0;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
}
@media (max-width: 868px) and (min-width: 768px) {
  #wrapper .chat-wrapper .chat-inner .chat-header {
    left: 340px !important;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
}
#wrapper .chat-wrapper .chat-inner .chat-header .navbar-toggle {
  float: left;
  margin-top: 0;
  color: #777;
}
#wrapper .chat-wrapper .chat-inner .chat-header .navbar-toggle .notification-label {
  top: 4px;
  right: 2px;
}
#wrapper .chat-wrapper .chat-inner .chat-header .btn {
  margin-top: 8px;
}
#wrapper .chat-wrapper .chat-inner .chat-body {
  padding-bottom: 20px;
}
#wrapper .chat-wrapper .chat-inner .chat-message {
  padding: 60px 20px 115px;
}
#wrapper .chat-wrapper .chat-inner .chat-box {
  position: fixed;
  bottom: 0;
  left: 444px;
  right: 0;
  padding: 15px;
  border-top: 1px solid #eee;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
@media (max-width: 767px) {
  #wrapper .chat-wrapper .chat-inner .chat-box {
    left: 0;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
}
@media (max-width: 868px) and (min-width: 768px) {
  #wrapper .chat-wrapper .chat-inner .chat-box {
    left: 340px !important;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
}
@media (max-width: 767px) {
  #wrapper .chat-wrapper.sidebar-display .chat-sidebar {
    left: 0;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
  #wrapper .chat-wrapper.sidebar-display .chat-inner {
    left: 250px;
    right: -250px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
  #wrapper .chat-wrapper.sidebar-display .chat-inner .chat-header,
  #wrapper .chat-wrapper.sidebar-display .chat-inner .chat-box {
    left: 250px;
    right: -250px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
}
@media (min-width: 768px) {
  #wrapper.sidebar-mini .chat-wrapper .chat-sidebar {
    left: 90px !important;
  }
  #wrapper.sidebar-mini .chat-wrapper .chat-inner {
    left: 340px !important;
  }
  #wrapper.sidebar-mini .chat-wrapper .chat-inner .chat-header,
  #wrapper.sidebar-mini .chat-wrapper .chat-inner .chat-box {
    left: 340px !important;
  }
}
@media (max-width: 767px) {
  #wrapper.sidebar-display .chat-wrapper.sidebar-display .chat-sidebar {
    left: 194px;
  }
  #wrapper.sidebar-display .chat-wrapper.sidebar-display .chat-inner {
    left: 444px;
    right: -444px;
  }
  #wrapper.sidebar-display .chat-wrapper.sidebar-display .chat-inner .chat-header,
  #wrapper.sidebar-display .chat-wrapper.sidebar-display .chat-inner .chat-box {
    left: 444px;
    right: -444px;
  }
  #wrapper.sidebar-display .chat-wrapper .chat-inner {
    left: 194px;
    right: -194px;
  }
  #wrapper.sidebar-display .chat-wrapper .chat-inner .chat-header,
  #wrapper.sidebar-display .chat-wrapper .chat-inner .chat-box {
    left: 194px;
    right: -194px;
  }
}
.gallery-header {
  padding: 10px;
  background: #e6e6e6;
}
@media (max-width: 767px) {
  #movieSlider {
    margin-bottom: 20px;
  }
}
#movieSlider .carousel-inner .item {
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.8s ease;
  -webkit-transition: opacity 0.8s ease;
  -moz-transition: opacity 0.8s ease;
  -ms-transition: opacity 0.8s ease;
  -o-transition: opacity 0.8s ease;
}
@media (max-width: 400px) {
  #movieSlider .carousel-inner .item {
    height: 200px;
  }
}
@media (min-width: 401px) and (max-width: 480px) {
  #movieSlider .carousel-inner .item {
    height: 250px;
  }
  #movieSlider .carousel-inner .item h2 {
    font-size: 17px;
  }
}
@media (min-width: 481px) and (max-width: 979px) {
  #movieSlider .carousel-inner .item {
    height: 300px;
  }
}
@media (min-width: 980px) {
  #movieSlider .carousel-inner .item {
    height: 350px;
  }
}
#movieSlider .carousel-inner .item .slide-caption {
  animation: none;
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  -o-animation: none;
}
#movieSlider .carousel-inner .item.active {
  opacity: 1;
  transition: opacity 0.8s ease;
  -webkit-transition: opacity 0.8s ease;
  -moz-transition: opacity 0.8s ease;
  -ms-transition: opacity 0.8s ease;
  -o-transition: opacity 0.8s ease;
}
#movieSlider .carousel-inner .item.left.active {
  left: 0;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.8s ease;
  -webkit-transition: opacity 0.8s ease;
  -moz-transition: opacity 0.8s ease;
  -ms-transition: opacity 0.8s ease;
  -o-transition: opacity 0.8s ease;
}
#movieSlider .carousel-inner .item.left.next {
  opacity: 1;
  transition: opacity 0.8s ease;
  -webkit-transition: opacity 0.8s ease;
  -moz-transition: opacity 0.8s ease;
  -ms-transition: opacity 0.8s ease;
  -o-transition: opacity 0.8s ease;
}
#movieSlider .carousel-inner .item.right.active {
  left: 0;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.8s ease;
  -webkit-transition: opacity 0.8s ease;
  -moz-transition: opacity 0.8s ease;
  -ms-transition: opacity 0.8s ease;
  -o-transition: opacity 0.8s ease;
}
#movieSlider .carousel-inner .item.right.prev {
  opacity: 1;
  transition: opacity 0.8s ease;
  -webkit-transition: opacity 0.8s ease;
  -moz-transition: opacity 0.8s ease;
  -ms-transition: opacity 0.8s ease;
  -o-transition: opacity 0.8s ease;
}
#movieSlider .carousel-inner .item img {
  position: absolute;
  left: 0;
  width: 100%;
}
@media (min-width: 1200px) {
  #movieSlider .carousel-inner .item img {
    top: -50px;
  }
}
#movieSlider .carousel-control {
  background-image: none;
  z-index: 2;
}
#movieSlider .carousel-control:hover,
#movieSlider .carousel-control:focus {
  opacity: 1;
}
#movieSlider .carousel-control span {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
}
#featuredMovie {
  height: 350px;
}
@media (max-width: 767px) {
  #featuredMovie {
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 979px) {
  #featuredMovie {
    height: 300px;
  }
}
@media (min-width: 980px) {
  #featuredMovie {
    height: 350px;
  }
}
#featuredMovie .featured-name {
  animation: text-color-animation 2s ease infinite;
  -webkit-animation: text-color-animation 2s ease infinite;
  -moz-animation: text-color-animation 2s ease infinite;
  -ms-animation: text-color-animation 2s ease infinite;
  -o-animation: text-color-animation 2s ease infinite;
}
.navbar-nav > li {
  border-bottom: 2px solid transparent;
  transition: border-color 0.4s ease;
  -webkit-transition: border-color 0.4s ease;
  -moz-transition: border-color 0.4s ease;
  -ms-transition: border-color 0.4s ease;
  -o-transition: border-color 0.4s ease;
}
.navbar-nav > li.active {
  border-bottom: 2px solid #3c8dbc;
}
.navbar-nav > li:hover,
.navbar-nav > li:focus {
  border-bottom-color: #3c8dbc;
  transition: border-color 0.4s ease;
  -webkit-transition: border-color 0.4s ease;
  -moz-transition: border-color 0.4s ease;
  -ms-transition: border-color 0.4s ease;
  -o-transition: border-color 0.4s ease;
}
.movie-news {
  list-style: none;
}
.movie-news li {
  display: block;
  margin-bottom: 10px;
  padding: 0 15px;
  border-bottom: 1px solid #1a1a1a;
}
.movie-news li img {
  float: left;
  width: 130px;
  margin-right: 15px;
}
.movie-news li .news-wrapper .news-header {
  color: #3c8dbc;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 3px;
}
.jcarousel-wrapper {
  border: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  margin-bottom: 0;
}
.jcarousel-wrapper .jcarousel {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
}
.jcarousel-wrapper .jcarousel > ul > li {
  position: relative;
  float: left;
  border-color: transparent;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
.jcarousel-wrapper .jcarousel > ul > li a {
  display: block;
  padding: 10px 15px;
}
.jcarousel-wrapper .jcarousel > ul > li ul {
  width: auto;
}
.jcarousel-wrapper .jcarousel > ul > li ul > li {
  float: none;
  width: auto;
  height: auto;
}
.movie-jcarousel > ul > li {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.movie-jcarousel > ul > li:hover {
  background: rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.movie-list {
  list-style: none;
}
.movie-list li {
  display: block;
  padding: 15px;
  border-bottom: 1px solid #1a1a1a;
}
.movie-list li:last-child {
  border-bottom: none;
}
.movie-list li img {
  float: left;
  width: 70px;
  margin-right: 10px;
}
.jcarousel-control-next {
  top: -40px;
  right: 0;
  background-color: #111;
  width: 25px;
  height: 25px;
  color: #777;
  line-height: 23px;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  text-shadow: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.jcarousel-control-next:hover {
  background-color: #3c8dbc;
  color: #fff;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.jcarousel-control-prev {
  top: -40px;
  right: 32px;
  left: auto;
  width: 25px;
  height: 25px;
  background-color: #111;
  color: #777;
  line-height: 23px;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  text-shadow: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.jcarousel-control-prev:hover {
  background-color: #3c8dbc;
  color: #fff;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
#lockScreen {
  display: table;
  width: 100%;
  height: 100%;
  text-align: center;
}
#lockScreen.in .modal-dialog {
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
}
#lockScreen .modal-dialog {
  display: table-cell;
  width: 270px !important;
  vertical-align: middle;
  transform: translate(0, -40px);
  -webkit-transform: translate(0, -40px);
  -moz-transform: translate(0, -40px);
  -ms-transform: translate(0, -40px);
  -o-transform: translate(0, -40px);
}
#lockScreen .modal-dialog .modal-content {
  background-color: transparent;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
}
#lockScreen .modal-dialog .modal-content .input-group {
  width: 270px;
  margin: 0 auto;
}
#lockScreen .lock-screen-img {
  text-align: center;
}
#lockScreen .lock-screen-img img {
  border-radius: 50em;
  -moz-border-radius: 50em;
  -webkit-border-radius: 50em;
  width: 70px;
  height: 70px;
}
.fc-event {
  background: #6bafbd;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.fc-header-title h2 {
  font-size: 14px;
  line-height: 37px;
}
.fc-header {
  position: relative;
}
.fc-header td {
  background: transparent;
}
.fc-header-right {
  position: absolute;
  width: 100%;
  background: transparent;
  top: -43px;
  right: 0px;
  text-align: right;
}
.fc-header-center {
  position: absolute;
  top: 0;
  width: 80%;
  left: 10%;
  line-height: 37px;
  text-align: center;
}
.fc-button {
  position: relative;
  cursor: pointer;
  margin-top: -3px;
  background: #fff;
}
.fc-button.fc-button-prev,
.fc-button.fc-button-next {
  border-radius: 50em;
  -moz-border-radius: 50em;
  -webkit-border-radius: 50em;
  padding: 0 .3em;
  width: 28px;
  height: 25px;
}
.fc-button-today {
  display: none;
}
.fc-header-left {
  position: relative;
  width: 100%;
}
.fc-header .fc-header-left .fc-corner-left {
  float: left;
}
.fc-header .fc-header-left .fc-corner-right {
  float: right;
}
.gritter-info .gritter-title {
  color: #6bafbd;
}
.gritter-success .gritter-title {
  color: #65cea7;
}
.gritter-warning .gritter-title {
  color: #f3ce85;
}
.gritter-danger .gritter-title {
  color: #fc8675;
}
@media (max-width: 480px) {
  #gritter-notice-wrapper {
    right: 0;
  }
}
.custom-popup {
  background-color: rgba(0, 0, 0, 0.9);
  color: #777;
  text-align: center !important;
  padding: 15px;
}
.custom-popup.full-width {
  width: 100%;
}
.custom-popup.light {
  background-color: #f9f9f9;
}
.custom-popup {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
}
.popup_visible .custom-popup {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.parsley-error-list {
  list-style: none;
}
.parsley-error {
  border-color: #f53535;
  color: #b94a48;
}
.parsley-error:focus {
  border-color: #f53535 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 2px rgba(245, 53, 53, 0.6) !important;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 2px rgba(245, 53, 53, 0.6) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 2px rgba(245, 53, 53, 0.6) !important;
}
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  max-width: 600px;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}
.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}
.dd-list .dd-list {
  padding-left: 30px;
}
.dd-collapsed .dd-list {
  display: none;
}
.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}
.dd-handle {
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px;
  color: #777;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid #eee;
  background: #fff;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.dd-handle:hover {
  background: #f7f7f7;
  color: #777;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
}
.dd-item > button:before {
  content: '+';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}
.dd-item > button[data-action="collapse"]:before {
  content: '-';
}
.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff), -webkit-linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff);
  background-image: -moz-linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff), -moz-linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff);
  background-image: linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff), linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}
.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
}
.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}
.dd-dragel .dd-handle {
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}
.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
}
#nestable-menu {
  padding: 0;
  margin: 20px 0;
}
.dd-hover > .dd-handle {
  background: #2ea8e5 !important;
}
.dd3-content {
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px 5px 40px;
  color: #777;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid #eee;
  background: #fff;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.dd3-content:hover {
  color: #777;
  background: #f7f7f7;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.dd-dragel > .dd3-item > .dd3-content {
  margin: 0;
}
.dd3-item > button {
  margin-left: 30px;
}
.dd3-handle {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  cursor: pointer;
  width: 30px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #eee;
  background: #fff;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.dd3-handle:before {
  content: '≡';
  display: block;
  position: absolute;
  left: 0;
  top: 3px;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #777;
  font-size: 20px;
  font-weight: normal;
}
.dd3-handle:hover {
  background: #eee;
}
.spinner .input-group-btn > .btn + .btn {
  margin-left: 0;
  height: 18px;
  border-radius: 0 0 2px 0 !important;
  -moz-border-radius: 0 0 2px 0 !important;
  -webkit-border-radius: 0 0 2px 0 !important;
}
.spinner .input-group-btn .btn {
  height: 17px;
  line-height: 1px;
  border-radius: 0 2px 0 0 !important;
  -moz-border-radius: 0 2px 0 0 !important;
  -webkit-border-radius: 0 2px 0 0 !important;
}
.chosen-container {
  width: 100% !important;
}
.chosen-container-single .chosen-single,
.chosen-container-multi .chosen-choices li.search-field input[type=text] {
  height: 25px;
}
.tagsinput {
  width: 100% !important;
}
.dropzone .dz-default.dz-message {
  background-image: none;
  width: 100%;
  left: 0;
  margin-left: auto;
  text-align: center;
}
.dropzone .dz-default.dz-message span {
  display: inline;
  font-size: 30px;
}
.minicolors-theme-default .minicolors-input {
  height: auto;
}
.minicolors-theme-default.minicolors-position-right .minicolors-input {
  padding-left: 12px;
}
.seperator {
  padding-top: 10px;
}
.block {
  display: block !important;
}
.inline-block {
  display: inline-block !important;
}
.inline {
  display: inline !important;
}
.relative {
  position: relative;
}
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.scrollable {
  overflow-y: auto;
}
.overflow-visible {
  overflow: visible;
}
.overflow-hidden {
  overflow: hidden;
}
.circle {
  border-radius: 50em;
  -moz-border-radius: 50em;
  -webkit-border-radius: 50em;
}
.no-padding {
  padding: 0 !important;
}
.p-top-xs {
  padding-top: 5px;
}
.p-top-sm {
  padding-top: 10px;
}
.p-top-md {
  padding-top: 20px;
}
.padding-xs {
  padding: 5px !important;
}
.padding-sm {
  padding: 10px !important;
}
.padding-md {
  padding: 20px !important;
}
.padding-lg {
  padding: 40px !important;
}
.paddingLR-xs {
  padding: 0 5px;
}
.paddingLR-sm {
  padding: 0 10px;
}
.paddingLR-md {
  padding: 0 20px;
}
.paddingLR-lg {
  padding: 0 40px;
}
.paddingTB-xs {
  padding: 5px 0;
}
.paddingTB-md {
  padding: 10px 0;
}
.paddingTB-md {
  padding: 20px 0;
}
.paddingTB-lg {
  padding: 40px 0;
}
.no-margin {
  margin: 0 !important;
}
.margin-xs {
  margin: 5px !important;
}
.margin-sm {
  margin: 10px !important;
}
.margin-md {
  margin: 20px !important;
}
.margin-lg {
  margin: 40px !important;
}
.m-top-none {
  margin-top: 0;
}
.m-top-xs {
  margin-top: 5px;
}
.m-top-sm {
  margin-top: 10px;
}
.m-top-md {
  margin-top: 20px;
}
.m-top-lg {
  margin-top: 40px;
}
.m-left-xs {
  margin-left: 5px;
}
.m-left-sm {
  margin-left: 10px;
}
.m-left-md {
  margin-left: 20px;
}
.m-left-lg {
  margin-left: 40px;
}
.m-right-xs {
  margin-right: 5px;
}
.m-right-sm {
  margin-right: 10px;
}
.m-right-md {
  margin-right: 20px;
}
.m-right-lg {
  margin-right: 40px;
}
.m-bottom-xs {
  margin-bottom: 5px;
}
.m-bottom-sm {
  margin-bottom: 10px;
}
.m-bottom-md {
  margin-bottom: 20px;
}
.m-bottom-lg {
  margin-bottom: 40px;
}
.m-bottom-none {
  margin-bottom: 0;
}
.no-shadow {
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.middle {
  vertical-align: middle;
}
.rounded {
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
.no-rounded {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}
.no-border {
  border: none !important;
}
.border {
  border: 1px solid #eee;
}
.border-top {
  border-top: 1px solid #eee;
}
.border-left {
  border-left: 1px solid #eee;
}
.border-right {
  border-right: 1px solid #eee;
}
.border-bottom {
  border-bottom: 1px solid #eee;
}
.font-14 {
  font-size: 14px !important;
}
.font-15 {
  font-size: 15px !important;
}
.font-16 {
  font-size: 16px !important;
}
.font-normal {
  font-weight: normal !important;
}
.width-100 {
  width: 100%;
}
.box-sizing {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.primary-font {
  color: #3c8dbc;
}
.text-white {
  color: #fff;
}
.text-primary {
  color: #424f63;
}
.text-info {
  color: #6bafbd;
}
.text-success {
  color: #65cea7;
}
.text-warning {
  color: #f3ce85;
}
.text-danger {
  color: #fc8675;
}
.font-normal {
  font-weight: normal !important;
}
.text-shadow-white {
  text-shadow: 0 1px 0 #fff;
}
.bg-white {
  background-color: #fff;
}
.bg-light {
  background-color: #f1f5fc;
}
.bg-dark {
  background-color: #222222;
}
.bg-grey {
  background-color: #eeeeee;
  text-shadow: 0 1px #fff;
}
.bg-primary {
  background-color: #424f63;
  color: #BECFDF;
}
.bg-primary a {
  color: #BECFDF;
}
.bg-primary a:hover,
.bg-primary a:focus {
  color: #fff;
}
.bg-warning {
  background-color: #f3ce85;
  color: #fff;
}
.bg-info {
  background-color: #6bafbd;
  color: #fff;
}
.bg-success {
  background-color: #65cea7;
  color: #fff;
}
.bg-danger {
  background-color: #fc8675 !important;
  color: #fff !important;
}
.shadow-pulse {
  animation: shadow-pulse 1s linear infinite;
  -webkit-animation: shadow-pulse 1s linear infinite;
  -moz-animation: shadow-pulse 1s linear infinite;
  -ms-animation: shadow-pulse 1s linear infinite;
  -o-animation: shadow-pulse 1s linear infinite;
}
.no-animation * {
  animation: none !important;
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -ms-animation: none !important;
  -o-animation: none !important;
  transform: none !important;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
}
.preload * {
  animation: none !important;
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -ms-animation: none !important;
  -o-animation: none !important;
}
.transition-delay1 {
  transition-delay: 0.5s !important;
  -webkit-transition-delay: 0.5s !important;
  -moz-transition-delay: 0.5s !important;
  -ms-transition-delay: 0.5s !important;
  -o-transition-delay: 0.5s !important;
}
.transition-delay2 {
  transition-delay: 1s !important;
  -webkit-transition-delay: 1s !important;
  -moz-transition-delay: 1s !important;
  -ms-transition-delay: 1s !important;
  -o-transition-delay: 1s !important;
}
.transition-delay3 {
  transition-delay: 1.5s !important;
  -webkit-transition-delay: 1.5s !important;
  -moz-transition-delay: 1.5s !important;
  -ms-transition-delay: 1.5s !important;
  -o-transition-delay: 1.5s !important;
}
.fadeInUp {
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
}
.fadeInDown {
  animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -ms-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
}
.fadeInLeft {
  animation-name: fadeInLeft;
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -ms-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
}
.fadeInRight {
  animation-name: fadeInRight;
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  -ms-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
}
.fadeInUpLarge {
  animation-name: fadeInUpLarge;
  -webkit-animation-name: fadeInUpLarge;
  -moz-animation-name: fadeInUpLarge;
  -ms-animation-name: fadeInUpLarge;
  -o-animation-name: fadeInUpLarge;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
}
.fadeInDownLarge {
  animation-name: fadeInDownLarge;
  -webkit-animation-name: fadeInDownLarge;
  -moz-animation-name: fadeInDownLarge;
  -ms-animation-name: fadeInDownLarge;
  -o-animation-name: fadeInDownLarge;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
}
.fadeInLeftLarge {
  animation-name: fadeInLeftLarge;
  -webkit-animation-name: fadeInLeftLarge;
  -moz-animation-name: fadeInLeftLarge;
  -ms-animation-name: fadeInLeftLarge;
  -o-animation-name: fadeInLeftLarge;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
}
.fadeInRightLarge {
  animation-name: fadeInRightLarge;
  -webkit-animation-name: fadeInRightLarge;
  -moz-animation-name: fadeInRightLarge;
  -ms-animation-name: fadeInRightLarge;
  -o-animation-name: fadeInRightLarge;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
}
.bounceInDown {
  animation-name: bounceInDown;
  -webkit-animation-name: bounceInDown;
  -moz-animation-name: bounceInDown;
  -ms-animation-name: bounceInDown;
  -o-animation-name: bounceInDown;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
}
.bounceIn {
  animation-name: bounceIn;
  -webkit-animation-name: bounceIn;
  -moz-animation-name: bounceIn;
  -ms-animation-name: bounceIn;
  -o-animation-name: bounceIn;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
}
.flipInH {
  animation-name: flipInH;
  -webkit-animation-name: flipInH;
  -moz-animation-name: flipInH;
  -ms-animation-name: flipInH;
  -o-animation-name: flipInH;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
}
.flipInV {
  animation-name: flipInV;
  -webkit-animation-name: flipInV;
  -moz-animation-name: flipInV;
  -ms-animation-name: flipInV;
  -o-animation-name: flipInV;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
}
.animation-delay1 {
  animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -ms-animation-delay: 0.1s;
  -o-animation-delay: 0.1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
}
.animation-delay2 {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -ms-animation-delay: 0.3s;
  -o-animation-delay: 0.3s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
}
.animation-delay3 {
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -ms-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
}
.animation-delay4 {
  animation-delay: 0.7s;
  -webkit-animation-delay: 0.7s;
  -moz-animation-delay: 0.7s;
  -ms-animation-delay: 0.7s;
  -o-animation-delay: 0.7s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
}
.animation-delay5 {
  animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
  -ms-animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
}
.animation-delay6 {
  animation-delay: 1.1s;
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s;
  -ms-animation-delay: 1.1s;
  -o-animation-delay: 1.1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
}
.animation-delay7 {
  animation-delay: 1.3s;
  -webkit-animation-delay: 1.3s;
  -moz-animation-delay: 1.3s;
  -ms-animation-delay: 1.3s;
  -o-animation-delay: 1.3s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
}
.animation-delay8 {
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  -ms-animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
}
.animation-delay9 {
  animation-delay: 1.7s;
  -webkit-animation-delay: 1.7s;
  -moz-animation-delay: 1.7s;
  -ms-animation-delay: 1.7s;
  -o-animation-delay: 1.7s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
}
.animation-delay10 {
  animation-delay: 1.9s;
  -webkit-animation-delay: 1.9s;
  -moz-animation-delay: 1.9s;
  -ms-animation-delay: 1.9s;
  -o-animation-delay: 1.9s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -ms-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
}
@-moz-keyframes bounce_followingBallsG {
  0% {
    left: 0px;
    background-color: #000;
  }
  50% {
    left: 236px;
    background-color: #fff;
  }
  100% {
    left: 0px;
    background-color: #000;
  }
}
@-webkit-keyframes bounce_followingBallsG {
  0% {
    left: 0px;
    background-color: #000;
  }
  50% {
    left: 236px;
    background-color: #fff;
  }
  100% {
    left: 0px;
    background-color: #000;
  }
}
@-ms-keyframes bounce_followingBallsG {
  0% {
    left: 0px;
    background-color: #000;
  }
  50% {
    left: 236px;
    background-color: #fff;
  }
  100% {
    left: 0px;
    background-color: #000;
  }
}
@-o-keyframes bounce_followingBallsG {
  0% {
    left: 0px;
    background-color: #000;
  }
  50% {
    left: 236px;
    background-color: #fff;
  }
  100% {
    left: 0px;
    background-color: #000;
  }
}
@keyframes bounce_followingBallsG {
  0% {
    left: 0px;
    background-color: #000;
  }
  50% {
    left: 236px;
    background-color: #fff;
  }
  100% {
    left: 0px;
    background-color: #000;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0px);
  }
}
@-ms-keyframes fadeInUp {
  0% {
    opacity: 0;
    -ms-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateY(0);
  }
}
@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0px);
  }
}
@-ms-keyframes fadeInDown {
  0% {
    opacity: 0;
    -ms-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateY(0);
  }
}
@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0px);
  }
}
@-ms-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -ms-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateX(0);
  }
}
@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0px);
  }
}
@-ms-keyframes fadeInRight {
  0% {
    opacity: 0;
    -ms-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateX(0);
  }
}
@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInUpLarge {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInUpLarge {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInUpLarge {
  0% {
    opacity: 0;
    -moz-transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0px);
  }
}
@-ms-keyframes fadeInUpLarge {
  0% {
    opacity: 0;
    -ms-transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateY(0);
  }
}
@-o-keyframes fadeInUpLarge {
  0% {
    opacity: 0;
    -o-transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInDownLarge {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInDownLarge {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInDownLarge {
  0% {
    opacity: 0;
    -moz-transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0px);
  }
}
@-ms-keyframes fadeInDownLarge {
  0% {
    opacity: 0;
    -ms-transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateY(0);
  }
}
@-o-keyframes fadeInDownLarge {
  0% {
    opacity: 0;
    -o-transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInLeftLarge {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInLeftLarge {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeftLarge {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0px);
  }
}
@-ms-keyframes fadeInLeftLarge {
  0% {
    opacity: 0;
    -ms-transform: translateX(100px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateX(0);
  }
}
@-o-keyframes fadeInLeftLarge {
  0% {
    opacity: 0;
    -o-transform: translateX(100px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInRightLarge {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInRightLarge {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInRightLarge {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0px);
  }
}
@-ms-keyframes fadeInRightLarge {
  0% {
    opacity: 0;
    -ms-transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateX(0);
  }
}
@-o-keyframes fadeInRightLarge {
  0% {
    opacity: 0;
    -o-transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@-webkit-keyframes slidedown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 500px;
  }
}
@-moz-keyframes slidedown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 500px;
  }
}
@-ms-keyframes slidedown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 500px;
  }
}
@-o-keyframes slidedown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 500px;
  }
}
@keyframes slidedown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 500px;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeInRotate {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px) rotate(-60deg);
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeInRotate {
  0% {
    opacity: 0;
    -moz-transform: translateX(-20px) rotate(-60deg);
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeInRotate {
  0% {
    opacity: 0;
    -ms-transform: translateX(-20px) rotate(-60deg);
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeInRotate {
  0% {
    opacity: 0;
    -o-transform: translateX(-20px) rotate(-60deg);
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInRotate {
  0% {
    opacity: 0;
    transform: translateX(-20px) rotate(-60deg);
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeInUp100 {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px) scale(0.6);
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeInUp100 {
  0% {
    opacity: 0;
    -moz-transform: translateY(100px) scale(0.6);
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeInUp100 {
  0% {
    opacity: 0;
    -ms-transform: translateY(100px) scale(0.6);
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeInUp100 {
  0% {
    opacity: 0;
    -o-transform: translateY(100px) scale(0.6);
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInUp100 {
  0% {
    opacity: 0;
    transform: translateY(100px) scale(0.6);
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 3px rgba(50, 50, 50, 0.5);
    -moz-box-shadow: 0 0 3px rgba(50, 50, 50, 0.5);
    -webkit-box-shadow: 0 0 3px rgba(50, 50, 50, 0.5);
  }
  50% {
    box-shadow: 0 0 6px #323232;
    -moz-box-shadow: 0 0 6px #323232;
    -webkit-box-shadow: 0 0 6px #323232;
  }
  100% {
    box-shadow: 0 0 3px rgba(50, 50, 50, 0.5);
    -moz-box-shadow: 0 0 3px rgba(50, 50, 50, 0.5);
    -webkit-box-shadow: 0 0 3px rgba(50, 50, 50, 0.5);
  }
}
@-moz-keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 3px rgba(50, 50, 50, 0);
    -moz-box-shadow: 0 0 3px rgba(50, 50, 50, 0);
    -webkit-box-shadow: 0 0 3px rgba(50, 50, 50, 0);
  }
  50% {
    box-shadow: 0 0 6px #323232;
    -moz-box-shadow: 0 0 6px #323232;
    -webkit-box-shadow: 0 0 6px #323232;
  }
  100% {
    box-shadow: 0 0 3px rgba(50, 50, 50, 0);
    -moz-box-shadow: 0 0 3px rgba(50, 50, 50, 0);
    -webkit-box-shadow: 0 0 3px rgba(50, 50, 50, 0);
  }
}
@-ms-keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 3px rgba(50, 50, 50, 0);
    -moz-box-shadow: 0 0 3px rgba(50, 50, 50, 0);
    -webkit-box-shadow: 0 0 3px rgba(50, 50, 50, 0);
  }
  50% {
    box-shadow: 0 0 6px #323232;
    -moz-box-shadow: 0 0 6px #323232;
    -webkit-box-shadow: 0 0 6px #323232;
  }
  100% {
    box-shadow: 0 0 3px rgba(50, 50, 50, 0);
    -moz-box-shadow: 0 0 3px rgba(50, 50, 50, 0);
    -webkit-box-shadow: 0 0 3px rgba(50, 50, 50, 0);
  }
}
@-o-keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 3px rgba(50, 50, 50, 0);
    -moz-box-shadow: 0 0 3px rgba(50, 50, 50, 0);
    -webkit-box-shadow: 0 0 3px rgba(50, 50, 50, 0);
  }
  50% {
    box-shadow: 0 0 6px #323232;
    -moz-box-shadow: 0 0 6px #323232;
    -webkit-box-shadow: 0 0 6px #323232;
  }
  100% {
    box-shadow: 0 0 3px rgba(50, 50, 50, 0);
    -moz-box-shadow: 0 0 3px rgba(50, 50, 50, 0);
    -webkit-box-shadow: 0 0 3px rgba(50, 50, 50, 0);
  }
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 3px rgba(50, 50, 50, 0);
    -moz-box-shadow: 0 0 3px rgba(50, 50, 50, 0);
    -webkit-box-shadow: 0 0 3px rgba(50, 50, 50, 0);
  }
  50% {
    box-shadow: 0 0 6px #323232;
    -moz-box-shadow: 0 0 6px #323232;
    -webkit-box-shadow: 0 0 6px #323232;
  }
  100% {
    box-shadow: 0 0 3px rgba(50, 50, 50, 0);
    -moz-box-shadow: 0 0 3px rgba(50, 50, 50, 0);
    -webkit-box-shadow: 0 0 3px rgba(50, 50, 50, 0);
  }
}
@keyframes text-moving {
  0% {
    left: 110%;
  }
  40% {
    left: 0%;
  }
  50% {
    left: 0%;
  }
  60% {
    left: 0%;
  }
  70% {
    left: 0%;
  }
  100% {
    left: -150%;
  }
}
@-webkit-keyframes text-moving {
  0% {
    left: 110%;
  }
  40% {
    left: 0%;
  }
  50% {
    left: 0%;
  }
  60% {
    left: 0%;
  }
  70% {
    left: 0%;
  }
  100% {
    left: -150%;
  }
}
@-moz-keyframes text-moving {
  0% {
    left: 110%;
  }
  40% {
    left: 0%;
  }
  50% {
    left: 0%;
  }
  60% {
    left: 0%;
  }
  70% {
    left: 0%;
  }
  100% {
    left: -150%;
  }
}
@-ms-keyframes text-moving {
  0% {
    left: 110%;
  }
  40% {
    left: 0%;
  }
  50% {
    left: 0%;
  }
  60% {
    left: 0%;
  }
  70% {
    left: 0%;
  }
  100% {
    left: -150%;
  }
}
@-o-keyframes text-moving {
  0% {
    left: 110%;
  }
  40% {
    left: 0%;
  }
  50% {
    left: 0%;
  }
  60% {
    left: 0%;
  }
  70% {
    left: 0%;
  }
  100% {
    left: -150%;
  }
}
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.7);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -moz-transform: scale(0.7);
  }
  50% {
    opacity: 1;
    -moz-transform: scale(1.05);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@-ms-keyframes bounceIn {
  0% {
    opacity: 0;
    -ms-transform: scale(0.7);
  }
  50% {
    opacity: 1;
    -ms-transform: scale(1.1);
  }
  100% {
    -ms-transform: scale(1);
  }
}
@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -o-transform: scale(0.7);
  }
  50% {
    opacity: 1;
    -o-transform: scale(1.1);
  }
  100% {
    -o-transform: scale(1);
  }
}
@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-1000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-1000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateY(30px);
  }
  80% {
    -moz-transform: translateY(-20%);
  }
  100% {
    -moz-transform: translateY(0);
  }
}
@-ms-keyframes bounceInDown {
  0% {
    opacity: 0;
    -ms-transform: translateY(-1000px);
  }
  60% {
    opacity: 1;
    -ms-transform: translateY(30px);
  }
  80% {
    -ms-transform: translateY(-20px);
  }
  100% {
    -ms-transform: translateY(0);
  }
}
@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-1000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateY(30px);
  }
  80% {
    -o-transform: translateY(-20%);
  }
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-1000px);
  }
  60% {
    opacity: 1;
    transform: translateY(30px);
  }
  80% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes bounceInDownSmall {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes bounceInDownSmall {
  0% {
    opacity: 0;
    -moz-transform: translateY(-100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateY(20px);
  }
  100% {
    -moz-transform: translateY(0);
  }
}
@-ms-keyframes bounceInDownSmall {
  0% {
    opacity: 0;
    -ms-transform: translateY(-100px);
  }
  60% {
    opacity: 1;
    -ms-transform: translateY(20px);
  }
  100% {
    -ms-transform: translateY(0);
  }
}
@-o-keyframes bounceInDownSmall {
  0% {
    opacity: 0;
    -o-transform: translateY(-100px);
  }
  60% {
    opacity: 1;
    -o-transform: translateY(20px);
  }
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes bounceInDownSmall {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  60% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-40px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
  }
}
@-moz-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  50% {
    -moz-transform: translateY(-40px);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(-50px);
  }
}
@-ms-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -ms-transform: translateY(0);
  }
  50% {
    -ms-transform: translateY(-40px);
  }
  100% {
    opacity: 0;
    -ms-transform: translateY(-50px);
  }
}
@-o-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }
  50% {
    -o-transform: translateY(-40px);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(-50px);
  }
}
@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    transform: translateY(-40px);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}
@-webkit-keyframes progress-start {
  0% {
    width: 0;
  }
}
@-moz-keyframes progress-start {
  0% {
    width: 0;
  }
}
@-ms-keyframes progress-start {
  0% {
    width: 0;
  }
}
@-o-keyframes progress-start {
  0% {
    width: 0;
  }
}
@keyframes progress-start {
  0% {
    width: 0;
  }
}
@-webkit-keyframes flipInH {
  0% {
    -webkit-transform: perspective(1000px) rotateY(90deg);
    opacity: 0;
  }
  33% {
    -webkit-transform: perspective(1000px) rotateY(-10deg);
  }
  66% {
    -webkit-transform: perspective(1000px) rotateY(10deg);
  }
  100% {
    -webkit-transform: perspective(1000px) rotateY(0deg);
    opacity: 1;
  }
}
@-moz-keyframes flipInH {
  0% {
    -moz-transform: perspective(1000px) rotateY(90deg);
    opacity: 0;
  }
  33% {
    -moz-transform: perspective(1000px) rotateY(-10deg);
  }
  66% {
    -moz-transform: perspective(1000px) rotateY(10deg);
  }
  100% {
    -moz-transform: perspective(1000px) rotateY(0deg);
    opacity: 1;
  }
}
@-ms-keyframes flipInH {
  0% {
    -ms-transform: perspective(1000px) rotateY(90deg);
    opacity: 0;
  }
  33% {
    -ms-transform: perspective(1000px) rotateY(-10deg);
  }
  66% {
    -ms-transform: perspective(1000px) rotateY(10deg);
  }
  100% {
    -ms-transform: perspective(1000px) rotateY(0deg);
    opacity: 1;
  }
}
@-o-keyframes flipInH {
  0% {
    -o-transform: perspective(1000px) rotateY(90deg);
    opacity: 0;
  }
  33% {
    -o-transform: perspective(1000px) rotateY(-10deg);
  }
  66% {
    -o-transform: perspective(1000px) rotateY(10deg);
  }
  100% {
    -o-transform: perspective(1000px) rotateY(0deg);
    opacity: 1;
  }
}
@keyframes flipInH {
  0% {
    transform: perspective(1000px) rotateY(90deg);
    opacity: 0;
  }
  33% {
    transform: perspective(1000px) rotateY(-10deg);
  }
  66% {
    transform: perspective(1000px) rotateY(10deg);
  }
  100% {
    transform: perspective(1000px) rotateY(0deg);
    opacity: 1;
  }
}
@-webkit-keyframes flipInV {
  0% {
    -webkit-transform: perspective(1000px) rotateX(90deg);
    opacity: 0;
  }
  33% {
    -webkit-transform: perspective(1000px) rotateX(-10deg);
  }
  66% {
    -webkit-transform: perspective(1000px) rotateX(10deg);
  }
  100% {
    -webkit-transform: perspective(1000px) rotateX(0deg);
    opacity: 1;
  }
}
@-moz-keyframes flipInV {
  0% {
    -moz-transform: perspective(1000px) rotateX(90deg);
    opacity: 0;
  }
  33% {
    -moz-transform: perspective(1000px) rotateX(-10deg);
  }
  66% {
    -moz-transform: perspective(1000px) rotateX(10deg);
  }
  100% {
    -moz-transform: perspective(1000px) rotateX(0deg);
    opacity: 1;
  }
}
@-ms-keyframes flipInV {
  0% {
    -ms-transform: perspective(1000px) rotateX(90deg);
    opacity: 0;
  }
  33% {
    -ms-transform: perspective(1000px) rotateX(-10deg);
  }
  66% {
    -ms-transform: perspective(1000px) rotateX(10deg);
  }
  100% {
    -ms-transform: perspective(1000px) rotateX(0deg);
    opacity: 1;
  }
}
@-o-keyframes flipInV {
  0% {
    -o-transform: perspective(1000px) rotateX(90deg);
    opacity: 0;
  }
  33% {
    -o-transform: perspective(1000px) rotateX(-10deg);
  }
  66% {
    -o-transform: perspective(1000px) rotateX(10deg);
  }
  100% {
    -o-transform: perspective(1000px) rotateX(0deg);
    opacity: 1;
  }
}
@keyframes flipInV {
  0% {
    transform: perspective(1000px) rotateX(90deg);
    opacity: 0;
  }
  33% {
    transform: perspective(1000px) rotateX(-10deg);
  }
  66% {
    transform: perspective(1000px) rotateX(10deg);
  }
  100% {
    transform: perspective(1000px) rotateX(0deg);
    opacity: 1;
  }
}
@-webkit-keyframes text-color-animation {
  50% {
    color: #fff;
  }
}
@-moz-keyframes text-color-animation {
  50% {
    color: #fff;
  }
}
@-ms-keyframes text-color-animation {
  50% {
    color: #fff;
  }
}
@-o-keyframes text-color-animation {
  50% {
    color: #fff;
  }
}
@keyframes text-color-animation {
  50% {
    color: #fff;
  }
}
