.required{
    color: #b94a48;
}

.spanSpiner{
    color: green;
    font-size: 19px;
    float: right;
}
.spanSpinerList{
    color: green;
    font-size: 25px;
    text-align: center
}
.dropdown-menu li a {
    cursor: pointer;
}
.geosuggest{
    width: 100%;
}
.geosuggest input{
    display: block;
    width: 100%;
    height: 31px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

input[type="radio"], input[type="checkbox"] {
    opacity: 0;
    position: relative;
    width: 15px;
    height: 15px;
    z-index: 2;
    cursor: pointer;
}

.postEventLoader{
    width: 400px;
    height: 148px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
}

.fade.in{
    opacity: 1;
    display: block;
    background-color: rgba(101, 101, 99, 0.5);
}