aside.skin-1 {
  background: #efefef;
}
aside.skin-1 .brand {
  background: #3c8dbc;
  color: #fff;
  border-bottom-color: #3b89b6;
}
aside.skin-1 .size-toggle {
  border-bottom-color: #dfdfdf;
  box-shadow: 0 1px 0 #f4f4f4;
  -moz-box-shadow: 0 1px 0 #f4f4f4;
  -webkit-box-shadow: 0 1px 0 #f4f4f4;
}
aside.skin-1 .size-toggle .btn {
  color: #777;
}
aside.skin-1 .size-toggle .btn:hover,
aside.skin-1 .size-toggle .btn:focus {
  color: #333;
}
aside.skin-1 .size-toggle .btn .icon-bar {
  background-color: #777;
}
aside.skin-1 .sidebar-inner {
  border-right-color: #dfdfdf;
}
aside.skin-1 .sidebar-inner .user-block {
  border-bottom-color: #dfdfdf;
  box-shadow: 0 1px 0 #f4f4f4;
  -moz-box-shadow: 0 1px 0 #f4f4f4;
  -webkit-box-shadow: 0 1px 0 #f4f4f4;
}
aside.skin-1 .sidebar-inner .user-block .detail {
  color: #777;
}
aside.skin-1 .sidebar-inner .user-block ul li a {
  color: #777;
}
aside.skin-1 .sidebar-inner .user-block ul li a:hover,
aside.skin-1 .sidebar-inner .user-block ul li a:focus {
  color: #333;
}
aside.skin-1 .sidebar-inner .search-block {
  border-bottom-color: #dfdfdf;
  box-shadow: 0 1px 0 #f4f4f4;
  -moz-box-shadow: 0 1px 0 #f4f4f4;
  -webkit-box-shadow: 0 1px 0 #f4f4f4;
}
aside.skin-1 .sidebar-inner .search-block input[type="text"],
aside.skin-1 .sidebar-inner .search-block .btn {
  background: #fff;
  color: #777;
  border-color: #dfdfdf;
}
aside.skin-1 .main-menu > ul > li {
  border-top-color: #f4f4f4;
  border-bottom-color: #dfdfdf;
}
aside.skin-1 .main-menu > ul > li.active,
aside.skin-1 .main-menu > ul > li.display {
  border-top-color: #dfdfdf;
}
aside.skin-1 .main-menu > ul > li.active > a,
aside.skin-1 .main-menu > ul > li.display > a {
  background: #f9f9f9;
}
aside.skin-1 .main-menu > ul > li > a {
  color: #777;
}
aside.skin-1 .main-menu > ul > li > a:hover,
aside.skin-1 .main-menu > ul > li > a:focus {
  background: #f9f9f9;
}
aside.skin-1 .main-menu > ul > li > a .menu-hover {
  background: #3c8dbc;
  box-shadow: 0 0 10px 0px #439bce;
  -moz-box-shadow: 0 0 10px 0px #439bce;
  -webkit-box-shadow: 0 0 10px 0px #439bce;
}
aside.skin-1 .main-menu > ul > li > .submenu  li  a{
  background-color: #e6e6e6;
}
aside.skin-1 .main-menu > ul > li > .submenu  li a .submenu-label{
  color: #777;
}
aside.skin-1 .main-menu > ul > li > .submenu  li a:hover .submenu-label,
aside.skin-1 .main-menu > ul > li > .submenu  li a:focus .submenu-label {
  color: #222;
}
aside.skin-1 .main-menu > ul > li > .submenu  li.active a{
  background: #f9f9f9;
}
aside.skin-1 .main-menu > ul > li > .submenu  li.active a .submenu-label{
  color: #555;
}
@media (max-width: 767px)   {
    aside.skin-1 .main-menu > ul > li > .dropdown-menu li a {
       color: #777;
    }
    
    aside.skin-1 .main-menu > ul > li > .dropdown-menu li a:hover 
    ,aside.skin-1 .main-menu > ul > li > .dropdown-menu li a:focus {
       color: #777;
       background: #f9f9f9;
    } 
}
#top-nav.skin-1 {
  background: #3c8dbc;
  border-bottom-color: #3b89b6;
}
#top-nav.skin-1 .navbar-toggle .icon-bar {
  background-color: #fff;
}
#top-nav.skin-1 .brand {
  color: #c3e3f5;	
  background: #3c8dbc;
}
#top-nav.skin-1 .page-title {
  color: #c3e3f5;
}
#top-nav.skin-1 .nav-notification > li.open {
  background: #3b89b6;
}
#top-nav.skin-1 .nav-notification > li:hover {
  background: #3b89b6;
}
#top-nav.skin-1 .nav-notification > li > a {
  color: #c3e3f5;
}
#top-nav.skin-1 .nav-notification > li > a:hover,
#top-nav.skin-1 .nav-notification > li > a:focus {
  color: #fff;
}
aside.skin-2 {
  background: #3a3a3a;
}
aside.skin-2 .brand {
  background: #3a3a3a;
  color: #fff;
  border-bottom-color: #444;
}
aside.skin-2 .size-toggle {
  border-bottom-color: #333;
  box-shadow: 0 1px 0 #444444;
  -moz-box-shadow: 0 1px 0 #444444;
  -webkit-box-shadow: 0 1px 0 #444444;
}
aside.skin-2 .size-toggle .btn {
  color: #777;
}
aside.skin-2 .size-toggle .btn:hover,
aside.skin-2 .size-toggle .btn:focus {
  color: #fff;
}
aside.skin-2 .size-toggle .btn .icon-bar {
  background-color: #777;
}
aside.skin-2 .sidebar-inner {
  border-right-color: #333;
}
aside.skin-2 .sidebar-inner .user-block {
  border-bottom-color: #333;
  box-shadow: 0 1px 0 #444444;
  -moz-box-shadow: 0 1px 0 #444444;
  -webkit-box-shadow: 0 1px 0 #444444;
}
aside.skin-2 .sidebar-inner .user-block .detail {
  color: #777;
}
aside.skin-2 .sidebar-inner .user-block ul li a {
  color: #777;
}
aside.skin-2 .sidebar-inner .user-block ul li a:hover,
aside.skin-2 .sidebar-inner .user-block ul li a:focus {
  color: #fff;
}
aside.skin-2 .sidebar-inner .search-block {
  border-bottom-color: #333;
  box-shadow: 0 1px 0 #444444;
  -moz-box-shadow: 0 1px 0 #444444;
  -webkit-box-shadow: 0 1px 0 #444444;
}
aside.skin-2 .sidebar-inner .search-block input[type="text"],
aside.skin-2 .sidebar-inner .search-block .btn {
  background: #fff;
  color: #777;
  border-color: #dfdfdf;
}
aside.skin-2 .main-menu > ul > li {
  border-top-color: #444;
  border-bottom-color: #333;
}
aside.skin-2 .main-menu > ul > li.active,
aside.skin-2 .main-menu > ul > li.display {
  border-top-color: #A93922;
}
aside.skin-2 .main-menu > ul > li.active > a,
aside.skin-2 .main-menu > ul > li.display > a {
  background: #BD3F26;
  color: #fff;
}
aside.skin-2 .main-menu > ul > li > a {
  color: #777;
}
aside.skin-2 .main-menu > ul > li > a:hover,
aside.skin-2 .main-menu > ul > li > a:focus {
  background: #BD3F26;
  color: #fff;
}
aside.skin-2 .main-menu > ul > li > a .menu-hover {
  background: #feffd8;
  box-shadow: 0 0 10px 0px #ffffff;
  -moz-box-shadow: 0 0 10px 0px #ffffff;
  -webkit-box-shadow: 0 0 10px 0px #ffffff;
}
aside.skin-2 .main-menu > ul > li > .submenu  li  a{
  background-color: #333;
}
aside.skin-2 .main-menu > ul > li > .submenu  li a .submenu-label{
  color: #777;
}
aside.skin-2 .main-menu > ul > li > .submenu  li a:hover .submenu-label,
aside.skin-2 .main-menu > ul > li > .submenu  li a:focus .submenu-label {
  color: #fff;
}
aside.skin-2 .main-menu > ul > li > .submenu  li.active a{
  background: #BD3F26;
}
aside.skin-2 .main-menu > ul > li > .submenu  li.active a .submenu-label{
  color: #fff;
}
@media (max-width: 767px)   {
    aside.skin-2 .main-menu > ul > li > .dropdown-menu li a {
       color: #777;
    }
    
    aside.skin-2 .main-menu > ul > li > .dropdown-menu li a:hover 
       ,aside.skin-2 .main-menu > ul > li > .dropdown-menu li a:focus {
        color: #fff;
        background: #bd3f26;
    }
}
#top-nav.skin-2 {
  background: #BD3F26;
  border-bottom-color: #A93922;
}
#top-nav.skin-2 .navbar-toggle .icon-bar {
  background-color: #fff;
}
#top-nav.skin-2 .brand {
  color: #f4cdc5;	
  background: #BD3F26;
}
#top-nav.skin-2 .page-title {
  color: #f4cdc5;
}
#top-nav.skin-2 .nav-notification > li.open {
  background: #A93922;
}
#top-nav.skin-2 .nav-notification > li:hover {
  background: #A93922;
}
#top-nav.skin-2 .nav-notification > li > a {
  color: #f4cdc5;
}
#top-nav.skin-2 .nav-notification > li > a:hover,
#top-nav.skin-2 .nav-notification > li > a:focus {
  color: #fff;
}
aside.skin-3 {
  background: #3e6b96;
}
aside.skin-3 .brand {
  background: #3e6b96;
  color: #D6E8F7;
  border-bottom-color: #4577A5;
}
aside.skin-3 .size-toggle {
  border-bottom-color: #396389;
  box-shadow: 0 1px 0 #4577a5;
  -moz-box-shadow: 0 1px 0 #4577a5;
  -webkit-box-shadow: 0 1px 0 #4577a5;
}
aside.skin-3 .size-toggle .btn {
  color: #D6E8F7;
}
aside.skin-3 .size-toggle .btn:hover,
aside.skin-3 .size-toggle .btn:focus {
  color: #fff;
}
aside.skin-3 .size-toggle .btn .icon-bar {
  background-color: #D6E8F7;
}
aside.skin-3 .sidebar-inner {
  border-right-color: #396389;
}
aside.skin-3 .sidebar-inner .user-block {
  border-bottom-color: #396389;
  box-shadow: 0 1px 0 #4577a5;
  -moz-box-shadow: 0 1px 0 #4577a5;
  -webkit-box-shadow: 0 1px 0 #4577a5;
}
aside.skin-3 .sidebar-inner .user-block .detail {
  color: #D6E8F7;
}
aside.skin-3 .sidebar-inner .user-block ul li a {
  color: #D6E8F7;
}
aside.skin-3 .sidebar-inner .user-block ul li a:hover,
aside.skin-3 .sidebar-inner .user-block ul li a:focus {
  color: #fff;
}
aside.skin-3 .sidebar-inner .search-block {
  border-bottom-color: #396389;
  box-shadow: 0 1px 0 #4577a5;
  -moz-box-shadow: 0 1px 0 #4577a5;
  -webkit-box-shadow: 0 1px 0 #4577a5;
}
aside.skin-3 .sidebar-inner .search-block input[type="text"],
aside.skin-3 .sidebar-inner .search-block .btn {
  background: #fff;
  color: #777;
  border-color: #dfdfdf;
}
aside.skin-3 .main-menu > ul > li {
  border-top-color: #4577A5;
  border-bottom-color: #396389;
}
aside.skin-3 .main-menu > ul > li.active,
aside.skin-3 .main-menu > ul > li.display {
  border-top-color: #396389;
}
aside.skin-3 .main-menu > ul > li.active > a,
aside.skin-3 .main-menu > ul > li.display > a {
  background: #396389;
  color: #fff;
}
aside.skin-3 .main-menu > ul > li > a {
  color: #D6E8F7;
}
aside.skin-3 .main-menu > ul > li > a:hover,
aside.skin-3 .main-menu > ul > li > a:focus {
  background: #396389;
  color: #fff;
}
aside.skin-3 .main-menu > ul > li > a .menu-hover {
  background: #feffd8;
  box-shadow: 0 0 10px 0px #ffffff;
  -moz-box-shadow: 0 0 10px 0px #ffffff;
  -webkit-box-shadow: 0 0 10px 0px #ffffff;
}
aside.skin-3 .main-menu > ul > li > .submenu  li  a{
  background-color: #4577a5;
}
aside.skin-3 .main-menu > ul > li > .submenu  li a .submenu-label{
  color: #D6E8F7;
}
aside.skin-3 .main-menu > ul > li > .submenu  li a:hover .submenu-label,
aside.skin-3 .main-menu > ul > li > .submenu  li a:focus .submenu-label {
  color: #fff;
}
aside.skin-3 .main-menu > ul > li > .submenu  li.active a{
  background: #396389;
}
aside.skin-3 .main-menu > ul > li > .submenu  li.active a .submenu-label{
  color: #fff;
}
@media (max-width: 767px)   {
    aside.skin-3 .main-menu > ul > li > .dropdown-menu li a {
       color: #d6e8f7;
    }
    
    aside.skin-3 .main-menu > ul > li > .dropdown-menu li a:hover 
    ,aside.skin-3 .main-menu > ul > li > .dropdown-menu li a:focus {
        color: #fff;
        background: #396389;
    }
}
#top-nav.skin-3 {
  background: #fff;
  border-bottom-color: #eee;
}
#top-nav.skin-3 .navbar-toggle .icon-bar {
  background-color: #999;
}
#top-nav.skin-3 .brand {
  color: #fff;
  background: #3e6b96;  
}
#top-nav.skin-3 .page-title {
  color: #999;
}
#top-nav.skin-3 .nav-notification > li.open {
  background: rgba(252, 252, 252, 0.9);
}
#top-nav.skin-3 .nav-notification > li:hover {
  background: rgba(252, 252, 252, 0.9);
}
#top-nav.skin-3 .nav-notification > li > a {
  color: #999;
}
#top-nav.skin-3 .nav-notification > li > a:hover,
#top-nav.skin-3 .nav-notification > li > a:focus {
  color: #777;
}
aside.skin-4 {
  background: #635247;
}
aside.skin-4 .brand {
  background: #635247;
  color: #F7E4D7;
  border-bottom-color: #55473E;
}
aside.skin-4 .size-toggle {
  border-bottom-color: #55473E;
  box-shadow: 0 1px 0 #756155;
  -moz-box-shadow: 0 1px 0 #756155;
  -webkit-box-shadow: 0 1px 0 #756155;
}
aside.skin-4 .size-toggle .btn {
  color: #F7E4D7;
}
aside.skin-4 .size-toggle .btn:hover,
aside.skin-4 .size-toggle .btn:focus {
  color: #fff;
}
aside.skin-4 .size-toggle .btn .icon-bar {
  background-color: #F7E4D7;
}
aside.skin-4 .sidebar-inner {
  border-right-color: #55473E;
}
aside.skin-4 .sidebar-inner .user-block {
  border-bottom-color: #55473E;
  box-shadow: 0 1px 0 #756155;
  -moz-box-shadow: 0 1px 0 #756155;
  -webkit-box-shadow: 0 1px 0 #756155;
}
aside.skin-4 .sidebar-inner .user-block .detail {
  color: #F7E4D7;
}
aside.skin-4 .sidebar-inner .user-block ul li a {
  color: #F7E4D7;
}
aside.skin-4 .sidebar-inner .user-block ul li a:hover,
aside.skin-4 .sidebar-inner .user-block ul li a:focus {
  color: #fff;
}
aside.skin-4 .sidebar-inner .search-block {
  border-bottom-color: #55473E;
  box-shadow: 0 1px 0 #756155;
  -moz-box-shadow: 0 1px 0 #756155;
  -webkit-box-shadow: 0 1px 0 #756155;
}
aside.skin-4 .sidebar-inner .search-block input[type="text"],
aside.skin-4 .sidebar-inner .search-block .btn {
  background: #fff;
  color: #777;
  border-color: #dfdfdf;
}
aside.skin-4 .main-menu > ul > li {
  border-top-color: #756155;
  border-bottom-color: #55473E;
}
aside.skin-4 .main-menu > ul > li.active,
aside.skin-4 .main-menu > ul > li.display {
  border-top-color: #55473E;
}
aside.skin-4 .main-menu > ul > li.active > a,
aside.skin-4 .main-menu > ul > li.display > a {
  background: #55473E;
  color: #fff;
}
aside.skin-4 .main-menu > ul > li > a {
  color: #F7E4D7;
}
aside.skin-4 .main-menu > ul > li > a:hover,
aside.skin-4 .main-menu > ul > li > a:focus {
  background: #55473E;
  color: #fff;
}
aside.skin-4 .main-menu > ul > li > a .menu-hover {
  background: #feffd8;
  box-shadow: 0 0 10px 0px #ffffff;
  -moz-box-shadow: 0 0 10px 0px #ffffff;
  -webkit-box-shadow: 0 0 10px 0px #ffffff;
}
aside.skin-4 .main-menu > ul > li > .submenu  li  a{
  background-color: #69594E;
}
aside.skin-4 .main-menu > ul > li > .submenu  li a .submenu-label{
  color: #F7E4D7;
}
aside.skin-4 .main-menu > ul > li > .submenu  li a:hover .submenu-label,
aside.skin-4 .main-menu > ul > li > .submenu  li a:focus .submenu-label {
  color: #fff;
}
aside.skin-4 .main-menu > ul > li > .submenu  li.active a{
  background: #55473E;
}
aside.skin-4 .main-menu > ul > li > .submenu  li.active a .submenu-label{
  color: #fff;
}
@media (max-width: 767px)   {
    aside.skin-4 .main-menu > ul > li > .dropdown-menu li a {
       color: #f7e4d7;
    }
    
    aside.skin-4 .main-menu > ul > li > .dropdown-menu li a:hover 
    ,aside.skin-4 .main-menu > ul > li > .dropdown-menu li a:focus {
        color: #fff;
        background: #55473e;
    }
}
#top-nav.skin-4 {
  background: #635247;
  border-bottom-color: #55473E;
}
#top-nav.skin-4 .navbar-toggle .icon-bar {
  background-color: #F7E4D7;
}
#top-nav.skin-4 .brand {
  color: #F7E4D7;	
  background: #635247;
}
#top-nav.skin-4 .page-title {
  color: #F7E4D7;
}
#top-nav.skin-4 .nav-notification > li.open {
  background: #55473E;
}
#top-nav.skin-4 .nav-notification > li:hover {
  background: #55473E;
}
#top-nav.skin-4 .nav-notification > li > a {
  color: #F7E4D7;
}
#top-nav.skin-4 .nav-notification > li > a:hover,
#top-nav.skin-4 .nav-notification > li > a:focus {
  color: #fff;
}

aside.skin-5 {
  background: #3a3a3a;
}
aside.skin-5 .size-toggle {
  border-bottom-color: #333;
  box-shadow: 0 1px 0 #444444;
  -moz-box-shadow: 0 1px 0 #444444;
  -webkit-box-shadow: 0 1px 0 #444444;
}
aside.skin-5 .size-toggle .btn {
  color: #999;
}
aside.skin-5 .size-toggle .btn:hover,
aside.skin-5 .size-toggle .btn:focus {
  color: #fff;
}
aside.skin-5 .size-toggle .btn .icon-bar {
  background-color: #999;
}
aside.skin-5 .sidebar-inner {
  border-right-color: #333;
}
aside.skin-5 .sidebar-inner .user-block {
  border-bottom-color: #333;
  box-shadow: 0 1px 0 #444444;
  -moz-box-shadow: 0 1px 0 #444444;
  -webkit-box-shadow: 0 1px 0 #444444;
}
aside.skin-5 .sidebar-inner .user-block .detail {
  color: #999;
}
aside.skin-5 .sidebar-inner .user-block ul li a {
  color: #999;
}
aside.skin-5 .sidebar-inner .user-block ul li a:hover,
aside.skin-5 .sidebar-inner .user-block ul li a:focus {
  color: #fff;
}
aside.skin-5 .sidebar-inner .search-block {
  border-bottom-color: #333;
  box-shadow: 0 1px 0 #444444;
  -moz-box-shadow: 0 1px 0 #444444;
  -webkit-box-shadow: 0 1px 0 #444444;
}
aside.skin-5 .sidebar-inner .search-block input[type="text"],
aside.skin-5 .sidebar-inner .search-block .btn {
  background: #fff;
  color: #999;
  border-color: #dfdfdf;
}
aside.skin-5 .main-menu > ul > li {
  border-top-color: #444;
  border-bottom-color: #333;
}
aside.skin-5 .main-menu > ul > li.active,
aside.skin-5 .main-menu > ul > li.display {
  border-top-color: #333;
}
aside.skin-5 .main-menu > ul > li.active > a,
aside.skin-5 .main-menu > ul > li.display > a {
  background: #333;
  color: #fff;
}
aside.skin-5 .main-menu > ul > li > a {
  color: #999;
}
aside.skin-5 .main-menu > ul > li > a:hover,
aside.skin-5 .main-menu > ul > li > a:focus {
  background: #333;
  color: #fff;
}
aside.skin-5 .main-menu > ul > li > a .menu-hover {
  background: #feffd8;
  box-shadow: 0 0 10px 0px #ffffff;
  -moz-box-shadow: 0 0 10px 0px #ffffff;
  -webkit-box-shadow: 0 0 10px 0px #ffffff;
}
aside.skin-5 .main-menu > ul > li > .submenu  li  a{
  background-color: #333;
}
aside.skin-5 .main-menu > ul > li > .submenu  li a .submenu-label{
  color: #999;
}
aside.skin-5 .main-menu > ul > li > .submenu  li a:hover .submenu-label,
aside.skin-5 .main-menu > ul > li > .submenu  li a:focus .submenu-label {
  color: #fff;
}
aside.skin-5 .main-menu > ul > li > .submenu  li.active a{
  background: #222;
}
aside.skin-5 .main-menu > ul > li > .submenu  li.active a .submenu-label{
  color: #fff;
}
@media (max-width: 767px)   {
    aside.skin-5 .main-menu > ul > li > .dropdown-menu li a {
       color: #999;
    }
    
    aside.skin-5 .main-menu > ul > li > .dropdown-menu li a:hover 
       ,aside.skin-5 .main-menu > ul > li > .dropdown-menu li a:focus {
        color: #fff;
        background: #333;
    }
}
#top-nav.skin-5 {
  background: #3a3a3a;
  border-bottom-color: #333;
}
#top-nav.skin-5 .navbar-toggle .icon-bar {
  background-color: #fff;
}
#top-nav.skin-5 .brand {
  color: #999;	
  background: #3a3a3a;
}
#top-nav.skin-5 .page-title {
  color: #999;
}
#top-nav.skin-5 .nav-notification > li.open {
  background: #333;
}
#top-nav.skin-5 .nav-notification > li:hover {
  background: #333;
}
#top-nav.skin-5 .nav-notification > li > a {
  color: #999;
}
#top-nav.skin-5 .nav-notification > li > a:hover,
#top-nav.skin-5 .nav-notification > li > a:focus {
  color: #fff;
}

aside.skin-6 {
  background: #495B6C;
}
aside.skin-6 .size-toggle {
  border-bottom-color: #415160;
  box-shadow: 0 1px 0 #506274;
  -moz-box-shadow: 0 1px 0 #506274;
  -webkit-box-shadow: 0 1px 0 #506274;
}
aside.skin-6 .size-toggle .btn {
  color: #BECFE0;
}
aside.skin-6 .size-toggle .btn:hover,
aside.skin-6 .size-toggle .btn:focus {
  color: #fff;
}
aside.skin-6 .size-toggle .btn .icon-bar {
  background-color: #BECFE0;
}
aside.skin-6 .sidebar-inner {
  border-right-color: #415160;
}
aside.skin-6 .sidebar-inner .user-block {
  border-bottom-color: #415160;
  box-shadow: 0 1px 0 #506274;
  -moz-box-shadow: 0 1px 0 #506274;
  -webkit-box-shadow: 0 1px 0 #506274;
}
aside.skin-6 .sidebar-inner .user-block .detail {
  color: #BECFE0;
}
aside.skin-6 .sidebar-inner .user-block ul li a {
  color: #BECFE0;
}
aside.skin-6 .sidebar-inner .user-block ul li a:hover,
aside.skin-6 .sidebar-inner .user-block ul li a:focus {
  color: #fff;
}
aside.skin-6 .sidebar-inner .search-block {
  border-bottom-color: #415160;
  box-shadow: 0 1px 0 #506274;
  -moz-box-shadow: 0 1px 0 #506274;
  -webkit-box-shadow: 0 1px 0 #506274;
}
aside.skin-6 .sidebar-inner .search-block input[type="text"],
aside.skin-6 .sidebar-inner .search-block .btn {
  background: #fff;
  color: #BECFE0;
  border-color: #dfdfdf;
}
aside.skin-6 .main-menu > ul > li {
  border-top-color: #506274;
  border-bottom-color: #415160;
}
aside.skin-6 .main-menu > ul > li.active,
aside.skin-6 .main-menu > ul > li.display {
  border-top-color: #415160;
}
aside.skin-6 .main-menu > ul > li.active > a,
aside.skin-6 .main-menu > ul > li.display > a {
  background: #415160;
  color: #fff;
}
aside.skin-6 .main-menu > ul > li > a {
  color: #BECFE0;
}
aside.skin-6 .main-menu > ul > li > a:hover,
aside.skin-6 .main-menu > ul > li > a:focus {
  background: #394754;
  color: #fff;
}
aside.skin-6 .main-menu > ul > li > a .menu-hover {
  background: #feffd8;
  box-shadow: 0 0 10px 0px #ffffff;
  -moz-box-shadow: 0 0 10px 0px #ffffff;
  -webkit-box-shadow: 0 0 10px 0px #ffffff;
}
aside.skin-6 .main-menu > ul > li > .submenu  li  a{
  background-color: #415160;
}
aside.skin-6 .main-menu > ul > li > .submenu  li a .submenu-label{
  color: #BECFE0;
}
aside.skin-6 .main-menu > ul > li > .submenu  li a:hover .submenu-label,
aside.skin-6 .main-menu > ul > li > .submenu  li a:focus .submenu-label {
  color: #fff;
}
aside.skin-6 .main-menu > ul > li > .submenu  li.active a{
  background: #2E3943;
}
aside.skin-6 .main-menu > ul > li > .submenu  li.active a .submenu-label{
  color: #fff;
}
@media (max-width: 767px)   {
    aside.skin-6 .main-menu > ul > li > .dropdown-menu li a {
       color: #BECFE0;
    }
    
    aside.skin-6 .main-menu > ul > li > .dropdown-menu li a:hover 
       ,aside.skin-6 .main-menu > ul > li > .dropdown-menu li a:focus {
        color: #fff;
        background: #415160;
    }
}
#top-nav.skin-6 {
  background: #2B333C;
  border-bottom-color: #415160;
}
#top-nav.skin-6 .navbar-toggle .icon-bar {
  background-color: #fff;
}
#top-nav.skin-6 .brand {
  color: #BECFE0;	
  background: #2B333C;
}
#top-nav.skin-6 .brand:hover
,#top-nav.skin-6 .brand:focus {
  color: #fff;	
}
#top-nav.skin-6 .page-title {
  color: #BECFE0;
}
#top-nav.skin-6 .nav-notification > li.open {
  background: #415160;
}
#top-nav.skin-6 .nav-notification > li:hover {
  background: #415160;
}
#top-nav.skin-6 .nav-notification > li > a {
  color: #BECFE0;
}
#top-nav.skin-6 .nav-notification > li > a:hover,
#top-nav.skin-6 .nav-notification > li > a:focus {
  color: #fff;
}
